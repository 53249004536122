/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useRemoveCartItemMutation } from 'api/shop'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Loader } from 'nxui/src'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { defaultCurrency, getBillingCycle, getDefaultCurrencyCode, getDefaultCurrencySymbol } from 'helpers/utils'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Summary } from 'containers/shop/shop.styles'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { ICartAddon, ICartItem } from 'models/shop/cart'

const cartItemVariants = {
    initial: { opacity: 0, x: -15 },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.35,
            ease: [0.83, 0, 0.17, 1]
        }
    },
    exit: {
        opacity: 0,
        x: 30,
        transition: {
            duration: 0.35,
            ease: [0.83, 0, 0.17, 1]
        }
    }
}

/**
 * useRemoveCartItemMutation abstraction that shares a fixedCacheKey across instances, but also provides information about whether
 * this instance, or another instance of the mutation was the one to originally trigger the mutation.
 */
const useSharedRemoveCartItemMutation = () => {
    /***** STATE *****/
    const [isInstanceRemoving, setIsInstanceRemoving] = useState(false)

    /***** QUERIES *****/
    const [_mutate, state] = useRemoveCartItemMutation({
        fixedCacheKey: 'remove-cart-item'
    })

    /***** FUNCTIONS *****/
    const mutate = async (...args: Parameters<typeof _mutate>) => {
        setIsInstanceRemoving(true)
        const result = await _mutate(...args).unwrap()
        setIsInstanceRemoving(false)
        return result
    }

    /***** RESULTS *****/
    return [mutate, { ...state, isInstanceRemoving }] as const
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function CartItem({ item }: { item: ICartItem }) {
    const { item_uuid, name, order_type, price, addons, billing_cycle, product: itemProduct, pending } = item

    /***** HOOKS *****/
    const params = useParams()
    const navigate = useNavigate()
    const { cart } = useAppSelector((state) => state.app)

    /***** QUERIES *****/
    const [removeCartItem, { isInstanceRemoving, isLoading: isRemoveCartItemLoading, isSuccess: isRemoveCartItemSuccess, reset }] =
        useSharedRemoveCartItemMutation()

    /***** EFFECTS *****/
    useEffect(() => {
        if (isRemoveCartItemSuccess && params?.addonId) {
            navigate('/shop/purchase')
        }

        return () => {
            reset()
        }
    }, [params?.addonId, isRemoveCartItemSuccess])

    /***** RENDER HELPERS *****/
    function renderServicePrice() {
        if (!price?.discount || price?.discount === '0.00') {
            return (
                <Summary.Product.Column>
                    <Summary.Product.Price>
                        {price ? `${defaultCurrency(price.subtotal)}` : `${getDefaultCurrencySymbol()}-.-- ${getDefaultCurrencyCode()}`}
                        {getBillingCycle(billing_cycle.name)}
                    </Summary.Product.Price>
                </Summary.Product.Column>
            )
        }

        return (
            <Summary.Product.Column>
                <Summary.Product.Original>
                    {defaultCurrency(price?.subtotal)}
                    <Summary.Product.BillingCycle>{getBillingCycle(billing_cycle.name)}</Summary.Product.BillingCycle>
                </Summary.Product.Original>
                <Summary.Product.Total className={`${price?.discount !== '0.00' ? 'highlight' : ''}`}>
                    {defaultCurrency(price?.total)}
                    {getBillingCycle(billing_cycle.name)}
                </Summary.Product.Total>
            </Summary.Product.Column>
        )
    }

    /***** RENDER *****/
    if (!cart) return null

    if (pending) {
        return (
            <motion.li variants={cartItemVariants} initial={'initial'} animate={'animate'} exit={'exit'}>
                <Summary.Product.Base active={!params.addonId && item_uuid === params.id}>
                    <Summary.Product.Row>
                        <Summary.Product.Column>
                            <Summary.Product.Title>{name ? name : itemProduct.name}</Summary.Product.Title>
                        </Summary.Product.Column>
                    </Summary.Product.Row>
                    <Summary.Product.Addons>
                        {addons?.map((addon) => (
                            <OwnAddon addon={addon} cartId={cart.uuid} itemId={item_uuid} />
                        ))}
                    </Summary.Product.Addons>
                    <Summary.Product.Row>
                        <Summary.Product.Status color={'attention'}>Not Configured</Summary.Product.Status>
                        <Summary.Product.Actions>
                            {(item_uuid !== params.id || params.addonId) && (
                                <Summary.Product.Configure
                                    color='select'
                                    onClick={() => navigate(`/shop/configure/${order_type !== 'all' ? 'domains' : 'services'}/${item_uuid}`)}
                                >
                                    Configure
                                </Summary.Product.Configure>
                            )}
                            <Summary.Product.Remove
                                color={'select'}
                                disabled={isRemoveCartItemLoading}
                                icon={isInstanceRemoving ? <Loader.Basic /> : undefined}
                                onClick={async () => {
                                    const result = await removeCartItem({
                                        uuid: cart.uuid,
                                        remove_items: [item_uuid]
                                    })

                                    if (result?.items) {
                                        const [firstCartItem] = result.items

                                        navigate(
                                            `/shop/configure/${firstCartItem.order_type !== 'all' ? 'domains' : 'services'}/${
                                                firstCartItem.item_uuid
                                            }`
                                        )
                                    } else {
                                        navigate('/shop/purchase')
                                    }
                                }}
                            >
                                Remove
                            </Summary.Product.Remove>
                        </Summary.Product.Actions>
                    </Summary.Product.Row>
                </Summary.Product.Base>
            </motion.li>
        )
    }

    return (
        <motion.li variants={cartItemVariants} initial={'initial'} animate={'animate'} exit={'exit'}>
            <Summary.Product.Base active={!params.addonId && item_uuid === params.id}>
                <Summary.Product.Row>
                    <Summary.Product.Column>
                        <Summary.Product.Title>{name}</Summary.Product.Title>
                        <Summary.Product.Subheading>
                            {itemProduct.service_type === 'domain' ? `${itemProduct.service_type} ${order_type}` : itemProduct.name}
                        </Summary.Product.Subheading>
                    </Summary.Product.Column>
                    {renderServicePrice()}
                </Summary.Product.Row>
                <Summary.Product.Addons>
                    {addons?.map((addon) => (
                        <OwnAddon addon={addon} cartId={cart.uuid} itemId={item_uuid} isPending={pending} />
                    ))}
                </Summary.Product.Addons>
                <Summary.Product.Row>
                    <Summary.Product.Status color={'info'}>Configured</Summary.Product.Status>
                    <Summary.Product.Actions>
                        {(params.addonId || item_uuid !== params.id) && (
                            <Summary.Product.Configure
                                color={'select'}
                                onClick={() => navigate(`/shop/configure/${order_type !== 'all' ? 'domains' : 'services'}/${item_uuid}`)}
                            >
                                Edit
                            </Summary.Product.Configure>
                        )}
                        <Summary.Product.Remove
                            disabled={isRemoveCartItemLoading}
                            icon={isInstanceRemoving ? <Loader.Basic /> : undefined}
                            color='select'
                            onClick={async () => {
                                await removeCartItem({ uuid: cart.uuid, remove_items: [item_uuid] })
                            }}
                        >
                            Remove
                        </Summary.Product.Remove>
                    </Summary.Product.Actions>
                </Summary.Product.Row>
            </Summary.Product.Base>
        </motion.li>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

type TOwnAddon = React.FC<{
    /**
     * The addon to render
     */
    addon: ICartAddon

    /**
     * The uuid of the cart
     */
    cartId: string

    /**
     * The uuid of the item
     */
    itemId: string

    /**
     * Whether the addon is pending
     */
    isPending?: boolean
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const OwnAddon: TOwnAddon = ({ addon, cartId, itemId, isPending }) => {
    const [removeAddon, { isLoading: isRemoveAddonLoading }] = useRemoveCartItemMutation({ fixedCacheKey: addon.item_uuid })
    const [, { isLoading: isAnyAddonLoading }] = useRemoveCartItemMutation({ fixedCacheKey: 'remove-any-addon' })

    const getAddonPrice = () => {
        if (!isPending && addon.price.discount !== '0.00') {
            return (
                <Summary.Product.Discount>
                    <Summary.Product.Before>
                        {defaultCurrency(addon.price.subtotal)}
                        {getBillingCycle(addon.billing_cycle.name)}
                    </Summary.Product.Before>
                    <Summary.Product.After>
                        {defaultCurrency(addon.price.total)}
                        {getBillingCycle(addon.billing_cycle.name)}
                    </Summary.Product.After>
                </Summary.Product.Discount>
            )
        }

        return (
            <Summary.Product.Discount>
                <span>
                    {defaultCurrency(addon.price.subtotal)}
                    {getBillingCycle(addon.billing_cycle.name)}
                </span>
            </Summary.Product.Discount>
        )
    }

    return (
        <AnimatePresence key={addon.item_uuid}>
            <Summary.Product.Addon
                key={addon.item_uuid}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ opacity: { duration: 0.2 } }}
                exit={{ opacity: 0 }}
            >
                <Summary.Product.Link to={`/shop/configure/services/${itemId}/addons/${addon.item_uuid}`}>
                    <Summary.Product.Name>
                        <Summary.Product.PlusCircleIcon width={'15px'} />
                        <Summary.Product.Subheading>{addon.name}</Summary.Product.Subheading>
                    </Summary.Product.Name>
                </Summary.Product.Link>
                <Summary.Product.Subtotal>
                    {getAddonPrice()}
                    <button type={'button'} onClick={() => removeAddon({ uuid: cartId, remove_items: [addon.item_uuid] })}>
                        {isRemoveAddonLoading ? (
                            <Summary.Product.LoaderWrapper>
                                <Loader.Basic />
                            </Summary.Product.LoaderWrapper>
                        ) : (
                            <Summary.Product.TrashIcon isAnyAddonLoading={isAnyAddonLoading} width={'15px'} />
                        )}
                    </button>
                </Summary.Product.Subtotal>
            </Summary.Product.Addon>
        </AnimatePresence>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

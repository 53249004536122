/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { motion } from 'framer-motion'
import { Outlet } from 'react-router-dom'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { componentTransitionVariants } from 'helpers/utils'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { Shop } from '../shop.styles'
import { ShopSummary } from '../shop.summary'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ShopLandingTemplate = () => (
    <Shop.Base as={motion.div} variants={componentTransitionVariants} initial='initial' animate='animate' exit='exit'>
        <Shop.Title>Shop</Shop.Title>
        <Shop.Content>
            <Shop.Browse.Base>
                <Outlet />
            </Shop.Browse.Base>
            <ShopSummary />
        </Shop.Content>
    </Shop.Base>
)
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *    HIGHT LEVEL ROUTES
 **********************************************************************************************************/
import Shop from 'containers/shop/shop'
import ShopCheckout from 'containers/shop/shop.checkout'
import UserLanding from 'containers/shop/user/user.landing'

import AuthSignUp from 'containers/authentication/components/auth.signup.component'
import Auth2FAForm from 'containers/authentication/forms/auth.2fa.form'
import AuthAccountForm from 'containers/authentication/forms/auth.account.form'
import AuthLoginForm from 'containers/authentication/forms/auth.auth.form'
import AuthForgotForm from 'containers/authentication/forms/auth.forgot.form'
import AuthRecoveryForm from 'containers/authentication/forms/auth.recovery.form'
import AuthResetForm from 'containers/authentication/forms/auth.reset.form'
import AuthSignUpForm from 'containers/authentication/forms/signup'
import ConfigureAddons from 'containers/shop/addons/addons.configure'
import ConfigureDomains from 'containers/shop/domain/configure/domain.configure'
import PurchaseDomains from 'containers/shop/domain/domain.shop'
import ConfigureServices from 'containers/shop/services/configure'
import PurchaseServices from 'containers/shop/services/services.shop'

import GuestRoot from 'router/guestRoot'

import { Container } from 'components/error/errorBoundary'
import { Status } from 'nxui/src'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import AuthLogin from 'containers/authentication/components/auth.login'
import { useWebsiteRoutes } from './helpers/hooks'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function GuestRouter() {
    /***** HOOKS *****/
    const { activeProductGroup } = useAppSelector((state) => state.shop)
    const { settingsSections } = useAppSelector((state) => state.app.appSettings.section)

    /***** ROUTES *****/
    const routerWebsiteRoutes = useWebsiteRoutes()
    const shopRoutes = [
        { path: 'shop', element: <Navigate to='/shop/purchase' replace /> },
        {
            path: 'shop',
            element: <Shop />,
            children: [
                {
                    index: true,
                    path: 'purchase',
                    element: activeProductGroup.type === 'domains' ? <PurchaseDomains /> : <PurchaseServices />
                },
                { path: 'landing', element: <UserLanding /> },
                {
                    path: 'configure/domains/:id',
                    element: <ConfigureDomains />
                },
                {
                    path: 'configure/services/:id',
                    element: <ConfigureServices />,
                    children: [
                        {
                            path: 'addons/:addonId',
                            element: <ConfigureAddons />
                        }
                    ]
                },
                {
                    path: 'checkout',
                    element: <ShopCheckout />
                }
            ]
        }
    ]

    /***** RENDER *****/
    if (typeof settingsSections === 'object' && settingsSections && !('login' in settingsSections)) {
        const router = createBrowserRouter([
            {
                path: '*',
                element: (
                    <Container>
                        <Status status={404} />
                    </Container>
                )
            }
        ])

        return <RouterProvider router={router} />
    }

    const router = createBrowserRouter([
        { path: '*', element: <Navigate to='/login' replace /> },
        {
            path: '/',
            element: <GuestRoot />,
            children: [
                {
                    path: 'login',
                    element: <AuthLogin />,
                    children: [
                        { index: true, element: <AuthLoginForm /> },
                        { path: 'reset-password/:token', element: <AuthResetForm /> },
                        { path: 'signup', element: <AuthSignUpForm /> },
                        { path: 'forgot', element: <AuthForgotForm /> },
                        {
                            path: 'two-factor',
                            element: <Auth2FAForm />
                        },
                        {
                            path: 'recovery',
                            element: <AuthRecoveryForm />
                        },
                        {
                            path: 'accounts',
                            element: <AuthAccountForm />
                        }
                    ]
                },
                { path: 'register/:token', element: <AuthSignUp /> },
                { path: 'register/:token/*', element: <AuthSignUp /> },
                { path: 'tos/*', element: <Navigate to='/login' replace /> },
                ...shopRoutes,
                ...routerWebsiteRoutes()
            ]
        }
    ])

    return <RouterProvider router={router} />
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

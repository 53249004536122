/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @private
 *
 * Internal hook used to get store state for the Domain Configuration Form.
 */
export const useConfigureDomainsSelector = (itemId?: string) => {
    /***** HOOKS *****/
    const {
        cart,
        appSettings: {
            provider: { default_country }
        },
        appSession: { user, current_account }
    } = useAppSelector((state) => state.app)

    /***** RENDER HELPERS *****/
    const cartItem = cart?.items?.find((item) => item.item_uuid === itemId)

    /***** RENDER *****/
    return {
        cart,
        cartItem,
        default_country,
        user,
        current_account
    }
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

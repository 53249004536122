/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { lazy } from 'react'
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENT IMPORTS
 **********************************************************************************************************/
import { Status } from 'nxui/src'

/**********************************************************************************************************
 *   CONTAINERS
 **********************************************************************************************************/
const Shop = lazy(() => import('containers/shop/shop'))

const Account = lazy(() => import('containers/account/account'))
const AuthSignUp = lazy(() => import('containers/authentication/components/auth.signup.component'))
const Billing = lazy(() => import('containers/billing/billing'))
const Dashboard = lazy(() => import('containers/dashboard/dashboard'))
const Domains = lazy(() => import('containers/domains/domains'))
const Services = lazy(() => import('containers/services/services'))

import Domain from 'containers/domains/domain'
import Service from 'containers/services/service'
import ConfigureAddons from 'containers/shop/addons/addons.configure'
import ConfigureDomains from 'containers/shop/domain/configure/domain.configure'
import PurchaseDomains from 'containers/shop/domain/domain.shop'
import Order from 'containers/shop/order'
import ConfigureServices from 'containers/shop/services/configure'
import PurchaseServices from 'containers/shop/services/services.shop'
import ShopCheckout from 'containers/shop/shop.checkout'

import ErrorBoundary, { Container } from 'components/error/errorBoundary'
import UserRoot from 'router/userRoot'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { TSection } from 'models/app'
import { useWebsiteRoutes } from './helpers/hooks'

/**********************************************************************************************************
 *   TYPES
 **********************************************************************************************************/
interface IPaths {
    login: Array<RouteObject>
    dashboard: Array<RouteObject>
    domain: Array<RouteObject>
    service: Array<RouteObject>
    billing: Array<RouteObject>
    account: Array<RouteObject>
    shop: Array<RouteObject>
    [key: string]: Array<RouteObject>
}

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
const availableSections = [
    {
        key: 'login',
        mainPath: 'login',
        canBeIndex: false
    },
    {
        key: 'dashboard',
        mainPath: 'dashboard',
        canBeIndex: true
    },
    {
        key: 'domain',
        mainPath: 'domains',
        canBeIndex: true
    },
    {
        key: 'service',
        mainPath: 'services',
        canBeIndex: true
    },
    {
        key: 'account',
        mainPath: 'account',
        canBeIndex: true
    },
    {
        key: 'billing',
        mainPath: 'billing',
        canBeIndex: true
    },
    {
        key: 'support',
        mainPath: 'support',
        canBeIndex: true
    }
]

export function getActiveSections(sections: TSection) {
    const activeSections: string[] = []

    Object.entries(sections).forEach(([key, value]) => {
        function isSectionActive() {
            // If the section is labled as false, or there is no UI for that section
            if (!value) return false
            // If the section has subsections but all subsections are labeled as false
            if (
                typeof value === 'object' &&
                Object.values(value).filter((nestedVal) => {
                    // Can have more sub-sections inside of a sub-section
                    if (typeof nestedVal !== 'object') return nestedVal
                    return Object.values(nestedVal).filter((doubleNestedVal) => doubleNestedVal).length > 0
                }).length === 0
            )
                return false
            return true
        }

        if (!isSectionActive()) return

        activeSections.push(key)
    })

    return activeSections
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function UserRouter() {
    /***** HOOKS *****/
    const websiteRoutes = useWebsiteRoutes()
    const { section: settingsSections } = useAppSelector((state) => state.app.appSettings)
    const { activeProductGroup } = useAppSelector((state) => state.shop)

    const activeSections = getActiveSections(settingsSections)

    /***** RENDER HELPERS *****/
    function buildRoutes() {
        if (!settingsSections || typeof settingsSections !== 'object') return []

        // Find the first account tab that is switched on in settings
        function getAccountIndexRoute() {
            if (settingsSections.account.twofactor) return 'two-factor'
            if (settingsSections.account.security) return 'security'
            if (settingsSections.account.users) return 'users'
            if (settingsSections.account.log) return 'emails'
            if (settingsSections.account.details) return 'details'
            return 'profile'
        }

        function getShopElements() {
            if (activeProductGroup.type === 'domains' && settingsSections.shop.domains) return <PurchaseDomains />
            if (settingsSections.shop.products) return <PurchaseServices />
            return <div>Nothing available for purchase at this time</div>
        }

        const paths: IPaths = {
            login: [
                { path: 'login', element: <Navigate to='/dashboard' replace /> },
                { path: 'login/*', element: <Navigate to='/dashboard' replace /> },
                { path: 'register/:token', element: <AuthSignUp /> }
            ],
            dashboard: [{ path: 'dashboard', element: <Dashboard /> }],
            domain: [
                {
                    path: 'domains',
                    element: <Domains />,
                    children: [{ path: ':id', element: <Domain />, children: [{ path: '*', element: <Domain /> }] }]
                }
            ],
            service: [
                {
                    path: 'services',
                    element: <Services />,
                    children: [{ path: ':id', element: <Service />, children: [{ path: '*', element: <Service /> }] }]
                }
            ],
            billing: [
                { path: 'billing', element: <Navigate to='/billing/invoices' replace /> },
                { path: 'billing/*', element: <Billing /> }
            ],
            account: [
                { path: 'account', element: <Navigate to={`/account/${getAccountIndexRoute()}`} replace /> },
                { path: 'account/*', element: <Account /> }
            ],
            shop: [
                { path: 'shop', element: <Navigate to='/shop/purchase' replace /> },
                {
                    path: 'shop',
                    element: <Shop />,
                    children: [
                        {
                            index: true,
                            path: 'purchase',
                            element: getShopElements()
                        },
                        {
                            path: 'configure/domains/:id',
                            element: <ConfigureDomains />
                        },
                        {
                            path: 'configure/services/:id',
                            element: <ConfigureServices />,
                            children: [
                                {
                                    path: 'addons/:addonId',
                                    element: <ConfigureAddons />
                                }
                            ]
                        },
                        {
                            path: 'checkout',
                            element: <ShopCheckout />
                        },
                        { path: 'order', element: <Navigate to='/shop/purchase' replace /> },
                        { path: 'order/:orderId', element: <Order /> }
                    ]
                }
            ]
        }

        // Find the first route that is active based on mercury settings, and redirect the "/" route there
        const indexRoute = `/${availableSections.find(({ key, canBeIndex }) => activeSections.includes(key) && canBeIndex && paths[key])?.mainPath}`

        const routes: Array<RouteObject> = [
            {
                path: '/',
                element: <UserRoot />,
                children: websiteRoutes(indexRoute),
                errorElement: <ErrorBoundary />
            },
            {
                path: '*',
                element: (
                    <Container>
                        <Status status={404} back={() => (window.location.href = window.location.origin)} />
                    </Container>
                )
            }
        ]

        // Only add the sections that are active based on mercury settings
        activeSections.forEach((sectionName) => {
            if (paths[sectionName]) routes[0].children?.push(...paths[sectionName])
        })

        return routes
    }

    const router = createBrowserRouter(buildRoutes())

    return <RouterProvider router={router} />
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

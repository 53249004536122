/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { motion } from 'framer-motion'
import Yup from 'helpers/yup'
import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useApplyAccountCreditMutation, usePayInvoiceMutation } from 'api/billing'
import { useAddPromoCodeToCartMutation, useCheckoutCartMutation, useViewCartQuery } from 'api/shop'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useLocalStorage } from 'helpers/hooks'
import { componentTransitionVariants, getCartTotals } from 'helpers/utils'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import PromotionForm from '../checkout/promotion.form'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { Checkout, Shop, Summary } from '../shop.styles'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TShopCheckoutTemplate = React.FC<{
    browserTitle: React.ReactNode
}>

const cartIdParser = (string: string) => {
    try {
        return Yup.string().required().uuid().validateSync(string)
    } catch (e) {
        return undefined
    }
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @requirements
 * - When page first loads, should check if promo exists in redux store and then attempt to add to cart
 */
export const ShopCheckoutTemplate: TShopCheckoutTemplate = ({ browserTitle }) => {
    /***** HOOKS *****/
    const navigate = useNavigate()
    const { cart } = useAppSelector((state) => state.app)
    const { checkoutPaymentFormData, checkoutAccountCredit, searchParamPromotion } = useAppSelector((state) => state.shop)
    const [cartId] = useLocalStorage<string | undefined>('cart_uuid', undefined, cartIdParser)

    /***** QUERIES *****/
    // ensure state.app.cart is repopulated after cache invalidation
    useViewCartQuery(cartId ?? '', { skip: !cartId })
    const [, { isLoading: isCheckoutCartLoading }] = useCheckoutCartMutation({ fixedCacheKey: 'checkout-cart' })
    const [, { isLoading: isPayInvoiceLoading }] = usePayInvoiceMutation({ fixedCacheKey: 'pay-checkout-invoice' })
    const [, { isLoading: isApplyAccountCreditLoading }] = useApplyAccountCreditMutation({ fixedCacheKey: 'apply-account-credit' })
    const [addPromoCodeToCart, { originalArgs }] = useAddPromoCodeToCartMutation()

    /***** EFFECTS *****/
    useEffect(() => {
        if (!searchParamPromotion) return
        if (searchParamPromotion === cart?.promo_code || !cartId) return

        // temporary fix since the endpoint doesn't return an error when the promo code is invalid
        if (originalArgs && originalArgs.uuid === cartId && originalArgs.code === searchParamPromotion) return

        addPromoCodeToCart({ uuid: cartId, code: searchParamPromotion })
    }, [])

    /***** RENDER HELPERS *****/
    const checkoutFormName =
        cart && Number(checkoutAccountCredit.amount) >= Number(cart.pricing?.total_due) ? 'accountCreditForm' : checkoutPaymentFormData?.name

    /***** RENDER *****/
    return (
        <Shop.Base as={motion.div} variants={componentTransitionVariants} initial='initial' animate='animate' exit='exit'>
            <Shop.Title>Shop</Shop.Title>
            {browserTitle}
            <Checkout.Container>
                <Outlet />
                <div>
                    <Checkout.Summary>
                        <PromotionForm />
                        <Summary.Subtotal>
                            <h2>Subtotal</h2>
                            <p>{getCartTotals('subtotal', cart)}</p>
                        </Summary.Subtotal>
                        <Summary.Discount>
                            <h2>Discount</h2>
                            <p>{getCartTotals('discount', cart)}</p>
                        </Summary.Discount>
                        <Summary.Subtotal>
                            <h2>Total</h2>
                            <p>{getCartTotals('total', cart)}</p>
                        </Summary.Subtotal>
                        {cart?.pricing?.total_tax && (
                            <Summary.TaxTotal>
                                <h2>Tax Total</h2>
                                <p>{getCartTotals('total_tax', cart)}</p>
                            </Summary.TaxTotal>
                        )}
                        <Summary.Total>
                            <h2>Due Today</h2>
                            <p>{getCartTotals('total_due', cart)}</p>
                        </Summary.Total>
                        {cart?.pricing?.tax_breakdown && cart.pricing.tax_breakdown.length >= 1 && (
                            <Summary.Description>Total is {cart?.pricing.tax_breakdown[0].name} inclusive.</Summary.Description>
                        )}
                    </Checkout.Summary>
                    <Checkout.Submit
                        type={'submit'}
                        color={'primary'}
                        form={checkoutFormName}
                        loading={(isCheckoutCartLoading || isPayInvoiceLoading || isApplyAccountCreditLoading) && 'Checking Out'}
                        //TODO - Do something else when one of these types is selected for payment
                        disabled={
                            !cart?.items ||
                            cart?.items.some(({ pending }) => pending) ||
                            isCheckoutCartLoading ||
                            isPayInvoiceLoading ||
                            isApplyAccountCreditLoading ||
                            !checkoutAccountCredit.isValid
                        }
                    >
                        Pay
                    </Checkout.Submit>
                    <Checkout.Back
                        type={'button'}
                        color={'flat'}
                        disabled={isCheckoutCartLoading || isPayInvoiceLoading || isApplyAccountCreditLoading}
                        onClick={() => navigate('/shop/purchase')}
                    >
                        Continue Shopping
                    </Checkout.Back>
                </div>
            </Checkout.Container>
        </Shop.Base>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

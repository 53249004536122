/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { ArrowsUpDownIcon, GlobeAltIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { Field, Formik, FormikHelpers, FormikProps } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { Dispatch, RefObject, SVGProps, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Loader } from 'components/loader'
import { Button, MultiSelect, Form as NXUIForm, Loader as NXUILoader, Shop as NXUIShop, Tooltip } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import ConfigureAddons from 'containers/shop/addons/addons.configure'
import { CustomFieldGroups } from 'containers/shop/domain/configure/components/customFieldGroup'
import { Transfer } from 'containers/shop/domain/domain.transfer'
import { Register } from 'containers/shop/domain/register'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { generateInitialValues } from 'containers/shop/helpers/initialValues'
import {
    componentTransitionVariants,
    defaultCurrency,
    getDefaultCurrencyCode,
    handleCheckboxQuantity,
    isProductPricingZero,
    regex,
    removeSkuFromConfigOptionName
} from 'helpers/utils'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useCreateCartMutation, useProductQuery, useUpdateCartItemsMutation } from 'api/shop'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Registration } from 'containers/shop/domain/domain.styles'
import { Configure, Services, Shop } from 'containers/shop/shop.styles'
import { _Style as Style } from './configure.style'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { ICart } from 'models/shop/cart'
import type { IConfigOption, IProduct } from 'models/shop/product.d'

export interface IServiceConfigurationForm {
    billing_cycle_id: number | null
    domain: string
    config_options: Array<{
        config_id: number
        option_id: number | string
        quantity: number | Array<string>
    }>
    custom_fields: Array<{
        id: number
        value: string | boolean
    }>
    addons: Array<{
        product_id: number
        billing_cycle_id: number | null
        config_options?: Array<{ config_id: number; option_id: number | string; quantity: number | Array<string> }>
    }>
}

interface ITab {
    label: string
    icon: React.ForwardRefExoticComponent<Omit<SVGProps<SVGSVGElement>, 'ref'>> & { title?: string; titleId?: string }
    render: JSX.Element
}

const getFormValidation = ({ require_domain, name_type }: IProduct) => {
    if (!require_domain) return Yup.string()

    switch (name_type) {
        case 'email_address':
            // Yup Email Validation does not work correctly - "yup": "^1.1.1"
            return Yup.object({
                domain: Yup.string().required('Required').matches(regex.email, 'Invalid email address')
            })
        case 'other':
        case 'domain':
        default:
            return Yup.object({
                domain: Yup.string().required('Domain name is required').matches(regex.domainWithExtension, 'Invalid domain name')
            })
    }
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function ConfigureServices() {
    const navigate = useNavigate()
    const params = useParams()
    const serviceConfigRef = useRef<FormikProps<IServiceConfigurationForm>>(null)
    const { cart } = useAppSelector((state) => state.app)
    const cartItem = cart?.items?.find((item) => item.item_uuid === params.id) ?? null

    /*   MEMO
     *****************************************************/
    const tabs = useMemo(
        () => [
            {
                label: 'Existing',
                icon: GlobeAltIcon,
                render: (
                    <Services.Tab.Base>
                        <Services.Tab.Embedded>
                            <Field
                                label={'Domain Name'}
                                name={'domain'}
                                type={'text'}
                                prefixes={['www.']}
                                disabled={serviceConfigRef.current?.isSubmitting}
                                component={NXUIForm.PrefixField}
                            />
                        </Services.Tab.Embedded>
                    </Services.Tab.Base>
                )
            },
            {
                label: 'Register',
                icon: PlusCircleIcon,
                render: (
                    <Services.Tab.Base>
                        <Register serviceConfigRef={serviceConfigRef} embedded={true} />
                    </Services.Tab.Base>
                )
            },
            {
                label: 'Transfer',
                icon: ArrowsUpDownIcon,
                render: (
                    <Services.Tab.Base>
                        <Transfer serviceConfigRef={serviceConfigRef} embedded={true} />
                    </Services.Tab.Base>
                )
            }
        ],
        []
    )

    /*   STATE
     *****************************************************/
    const [active, setActive] = useState<ITab>(tabs[0])

    /*   MUTATIONS / QUERIES
     *****************************************************/
    const [updateCart] = useUpdateCartItemsMutation(
        /* TEMP-START */
        // TODO: temp hack, see note in "containers/shop/shop.tsx"
        {
            fixedCacheKey: 'update-cart-from-services-configure[bug]'
        }
        /* TEMP-END */
    )
    const [createCart] = useCreateCartMutation()
    const { isFetching: isProductFetching, data: product } = useProductQuery(cartItem?.product.id ?? skipToken)

    useEffect(() => {
        if (!cart?.items) {
            navigate('/shop/purchase')
        }
    }, [cart])

    const renderAddons = (product: IProduct, cart: ICart, ref: RefObject<FormikProps<IServiceConfigurationForm>>) => {
        if (!product?.addons?.length) return null

        return (
            <>
                <Registration.Title>Addons</Registration.Title>
                <Services.Container>
                    {product.addons
                        .filter(({ status }) => status === 'active')
                        .map((addon) => {
                            const { pricing, id, name, description, features } = addon
                            const cartItem = cart?.items?.find(({ item_uuid }) => item_uuid === params.id)
                            const cartItemAddon = cartItem?.addons?.find(({ product }) => product.id === id)

                            return (
                                <NXUIShop.ServiceProduct
                                    key={id}
                                    availability={pricing?.length}
                                    isAdded={Boolean(cartItemAddon)}
                                    description={description}
                                    title={name}
                                    features={features?.length ? features : undefined}
                                    price={
                                        isProductPricingZero(addon.pricing)
                                            ? undefined
                                            : `${defaultCurrency(pricing[0]?.price)} ${getDefaultCurrencyCode()}`
                                    }
                                    dock={{
                                        title: 'Configure Addon',
                                        render: <ConfigureAddons addon={addon} parentServiceRef={ref} />
                                    }}
                                />
                            )
                        })}
                </Services.Container>
            </>
        )
    }

    const renderBillingCycle = (product: IProduct, ref: RefObject<FormikProps<IServiceConfigurationForm>>) => {
        const billingCycle = product.pricing.find(({ billing_cycle: { id } }) => id === ref.current?.values.billing_cycle_id)
        const billingCyclePrice = defaultCurrency(billingCycle?.price)

        return (
            <>
                <Registration.Title>
                    <Registration.Text>
                        <span>Billing Cycle</span>
                        <Registration.Highlight>
                            {billingCycle && billingCycle?.price !== '0.00' ? `+${billingCyclePrice}` : ''}
                        </Registration.Highlight>
                    </Registration.Text>
                </Registration.Title>
                <Services.Container>
                    <MultiSelect
                        options={product.pricing.map(({ billing_cycle }) => ({
                            type: 'button',
                            label: billing_cycle.name,
                            active: ref.current?.values.billing_cycle_id === billing_cycle.id,
                            onClick: () =>
                                ref.current?.setValues({
                                    ...ref.current.values,
                                    billing_cycle_id: billing_cycle.id,
                                    addons: ref.current.values.addons.map((addon) => ({
                                        ...addon,
                                        billing_cycle_id: billing_cycle.id
                                    }))
                                })
                        }))}
                    />
                </Services.Container>
            </>
        )
    }

    const renderInputType = (configOption: IConfigOption, formValues: IServiceConfigurationForm) => {
        if (!cartItem?.config_options) return null

        // Index of the cart item config options where the option_id = the id of the product config option being rendered
        const index = cartItem.config_options.findIndex(({ config }) => config.id === configOption.id)

        // The values of the product config option being rendered, sorted by display order
        const sortByDisplayOrder = [...configOption.values].sort((a, b) => a.display_order - b.display_order)

        // The value from the sorted values, found by matching the value id to the option id of a config option in the current form
        const optionValue = sortByDisplayOrder.find(({ id }) => {
            const foundOption = formValues.config_options[index]
            if (!foundOption) return false
            return id === Number(foundOption.option_id)
        })

        if (!optionValue) return ''

        const billingCycle = optionValue?.pricing.find(({ billing_cycle: { id } }) => id === formValues.billing_cycle_id)

        switch (configOption.input_type) {
            case 'dropdown':
                return (
                    <div key={configOption.id}>
                        <Services.ConfigOption.Label htmlFor={`config_options.${index}.option_id`}>
                            {removeSkuFromConfigOptionName(configOption.name)}
                            <span>{billingCycle && Number(billingCycle.price) > 0 && `+${defaultCurrency(billingCycle.price)}`}</span>
                        </Services.ConfigOption.Label>
                        <Field
                            id={`config_options.${index}.option_id`}
                            name={`config_options.${index}.option_id`}
                            type='select'
                            list={sortByDisplayOrder.map((configOptionValue) => ({
                                label: configOptionValue.name,
                                value: configOptionValue.id
                            }))}
                            component={NXUIForm.SelectField}
                        />
                    </div>
                )
            case 'checkbox':
                return (
                    <div key={configOption.id}>
                        <Services.ConfigOption.Label htmlFor={`config_options.${index}.quantity`}>
                            {removeSkuFromConfigOptionName(configOption.name)}
                            <span>{billingCycle && Number(billingCycle.price) > 0 && `+${defaultCurrency(billingCycle.price)}`}</span>
                        </Services.ConfigOption.Label>
                        {sortByDisplayOrder.map((configOptionValue) => (
                            <Field
                                id={`config_options.${index}.quantity`}
                                key={String(configOptionValue)}
                                name={`config_options.${index}.quantity`}
                                value={String(1)}
                                type={'checkbox'}
                                description={configOptionValue.name}
                                component={NXUIForm.CheckboxField}
                            />
                        ))}
                    </div>
                )
            case 'radio':
                return (
                    <div key={configOption.id} role={'group'} aria-labelledby={configOption.name}>
                        <Services.ConfigOption.Label htmlFor={`config_options.${index}.option_id`}>
                            {removeSkuFromConfigOptionName(configOption.name)}
                            <span>{billingCycle && Number(billingCycle.price) > 0 && `+${defaultCurrency(billingCycle.price)}`}</span>
                        </Services.ConfigOption.Label>
                        {sortByDisplayOrder.map((configOptionValue) => (
                            <Field
                                id={`config_options.${index}.option_id`}
                                key={String(configOptionValue.id)}
                                label={configOptionValue.name}
                                name={`config_options.${index}.option_id`}
                                value={String(configOptionValue.id)}
                                type={'radio'}
                                description={configOptionValue.name}
                                component={NXUIForm.RadioField}
                            />
                        ))}
                    </div>
                )
            case 'number':
                return (
                    <div key={configOption.id}>
                        {sortByDisplayOrder.map((configOptionValue) => (
                            <div key={configOptionValue.id}>
                                <Services.ConfigOption.Label htmlFor={`config_options.${index}.quantity`}>
                                    {`${removeSkuFromConfigOptionName(configOption.name)} (${configOptionValue.name})`}
                                    <span>{billingCycle && Number(billingCycle.price) > 0 && `+${defaultCurrency(billingCycle.price)}`}</span>
                                </Services.ConfigOption.Label>
                                <Field
                                    id={`config_options.${index}.quantity`}
                                    name={`config_options.${index}.quantity`}
                                    type='number'
                                    min={configOption.min_quantity.toString()}
                                    max={configOption.max_quantity.toString()}
                                    step='1'
                                    component={NXUIForm.NumberField}
                                />
                            </div>
                        ))}
                    </div>
                )
            default:
                return null
        }
    }

    const renderConfigOptions = (product: IProduct, formValues: IServiceConfigurationForm) => {
        if (!product.config_option_groups || (Array.isArray(product.config_option_groups) && product.config_option_groups.length === 0)) {
            return null
        }

        return (
            <>
                <Registration.Title>Configuration Options</Registration.Title>
                <Services.Content>
                    {product.config_option_groups.map((configOptionGroup) => (
                        <Services.ConfigOptionGroup key={configOptionGroup.id}>
                            <Services.GroupHeading>{configOptionGroup.name}</Services.GroupHeading>
                            {configOptionGroup.config_options.map((configOption) => renderInputType(configOption, formValues))}
                        </Services.ConfigOptionGroup>
                    ))}
                </Services.Content>
            </>
        )
    }

    /**
     * Renders the name fields. This can be a domain, email address or other and will apply the appropriate layout
     */
    const renderNameFields = (setActive: Dispatch<SetStateAction<ITab>>, active?: ITab, tabs?: Array<ITab>) => {
        switch (product?.name_type) {
            case 'email_address':
                return (
                    <>
                        <Registration.Title>Email Address</Registration.Title>
                        <Style.nameFields.emailFieldWrapper>
                            <Field
                                label='Email Address'
                                name='domain'
                                id='domain'
                                disabled={serviceConfigRef.current?.isSubmitting}
                                type='text'
                                component={NXUIForm.InputField}
                            />
                        </Style.nameFields.emailFieldWrapper>
                    </>
                )
            case 'domain':
            default:
                return (
                    <>
                        <Registration.Title>Domain Name</Registration.Title>
                        <Shop.Browse.Tabs.Options setActive={setActive} active={active} tabs={tabs} />
                        <Shop.Browse.Tabs.Content when={active} />
                    </>
                )
        }
    }

    const renderConfigureButton = (isValid: boolean, isSubmitting: boolean, errors: object) => {
        if (!isValid) {
            return (
                <Tooltip
                    as={'div'}
                    content={Object.values(errors).map((error, index) => (
                        <span key={index}>
                            {error}
                            <br />
                        </span>
                    ))}
                >
                    <Registration.Disable type={'button'} color={'flat'}>
                        Update Configuration{' '}
                        {isSubmitting && (
                            <Registration.ConfigurationLoaderWrapper>
                                <NXUILoader.Basic />
                            </Registration.ConfigurationLoaderWrapper>
                        )}
                    </Registration.Disable>
                </Tooltip>
            )
        }

        return (
            <Button type={'submit'} color={'primary'} disabled={isSubmitting}>
                Update Configuration{' '}
                {isSubmitting && (
                    <Registration.ConfigurationLoaderWrapper>
                        <NXUILoader.Basic />
                    </Registration.ConfigurationLoaderWrapper>
                )}
            </Button>
        )
    }

    /*   RENDER COMPONENT
     *****************************************************/
    if (params.addonId) return <Outlet />

    if (isProductFetching) {
        return (
            <Services.Loading
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.5, ease: [0.83, 0, 0.17, 1] } }}
                exit={{ opacity: 0 }}
            >
                <Loader message={'Fetching Product Configuration...'} width={48} height={48} />
            </Services.Loading>
        )
    }

    if (!cartItem || !product || !cart) return null

    type THandleSubmit = (values: IServiceConfigurationForm, formikHelpers: FormikHelpers<IServiceConfigurationForm>) => void

    const handleSubmit: THandleSubmit = async ({ billing_cycle_id, domain, config_options, custom_fields }) => {
        if (cart) {
            const result = await updateCart({
                uuid: cart.uuid,
                items: [
                    {
                        billing_cycle_id,
                        item_uuid: params.id,
                        name: domain,
                        order_type: 'all',
                        product_id: cartItem.product.id,
                        config_options: config_options.map((configOption) => ({
                            config_id: Number(configOption.config_id),
                            option_id: Number(configOption.option_id),
                            ...(handleCheckboxQuantity(configOption.quantity) && {
                                quantity: handleCheckboxQuantity(configOption.quantity)
                            })
                        })),
                        custom_fields: custom_fields
                            .filter(({ value }) => {
                                if (typeof value === 'string') return value.length >= 1
                                if (typeof value === 'boolean') return true
                                return false
                            })
                            .map(({ id, value }) => {
                                if (typeof value === 'boolean') {
                                    return {
                                        id,
                                        value: value ? '1' : '0'
                                    }
                                }
                                return {
                                    id,
                                    value
                                }
                            })
                    }
                ]
            }).unwrap()
            const pendingDomain = result.items?.find((item) => item.name === domain && item.pending)
            if (pendingDomain) {
                navigate(`/shop/configure/domains/${pendingDomain.item_uuid}`)
            } else {
                navigate('/shop/purchase')
            }
        } else {
            await createCart({
                items: [
                    {
                        name: domain,
                        order_type: 'all',
                        product_id: cartItem.product.id,
                        billing_cycle_id
                    }
                ]
            })
        }
    }

    return (
        <Formik
            enableReinitialize
            validateOnMount
            innerRef={serviceConfigRef}
            validationSchema={getFormValidation(product)}
            initialValues={generateInitialValues({ cartItem, addons: product.addons, customFieldGroups: product.custom_field_groups })}
            onSubmit={handleSubmit}
        >
            {({ isValid, isSubmitting, errors, values }) => (
                <AnimatePresence>
                    <motion.div variants={componentTransitionVariants} initial={'initial'} animate={'animate'} exit={'exit'}>
                        <Configure.Title>{product?.name}</Configure.Title>
                        <Registration.Form>
                            <Registration.Body>
                                {renderBillingCycle(product, serviceConfigRef)}
                                {product.require_domain && renderNameFields(setActive, active, tabs)}
                                {renderConfigOptions(product, values)}

                                {/* Custom Field Groups */}
                                {product.custom_field_groups?.map((group) => (
                                    <CustomFieldGroups key={group.id} group={group} />
                                ))}

                                {renderAddons(product, cart, serviceConfigRef)}
                            </Registration.Body>
                            <Registration.Footer>
                                <Button type={'button'} color={'secondary'} onClick={() => navigate('/shop/purchase')} disabled={isSubmitting}>
                                    Back
                                </Button>
                                {renderConfigureButton(isValid, isSubmitting, errors)}
                            </Registration.Footer>
                        </Registration.Form>
                    </motion.div>
                </AnimatePresence>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { GlobeAltIcon } from '@heroicons/react/20/solid'

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { shopAPI } from 'api/shop'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Loader } from 'components/NXLoader'
import { Bubble } from 'components/bubble'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getDefaultCurrencySymbol } from 'helpers/utils'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CompetitivePricesStyles as S } from './style'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TCompetitiveBubble = React.FC<{
    tld?: string
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CompetitiveBubble: TCompetitiveBubble = ({ tld }) => {
    /***** QUERIES *****/
    const { data: tldData } = shopAPI.endpoints.domainProductList.useQuery(undefined, {
        selectFromResult: ({ data, ...rest }) => ({ ...rest, data: data?.find(({ name }) => name === tld) })
    })

    const { data: pricingData, isSuccess } = shopAPI.endpoints.product.useQuery(tldData?.id as number, {
        skip: typeof tldData?.id !== 'number',
        selectFromResult: ({ data, ...rest }) => ({ ...rest, data: data?.pricing.find(({ billing_cycle }) => billing_cycle.name === '1 Year') })
    })

    /***** RENDER *****/
    return (
        <S.CompoundBubble icon={GlobeAltIcon}>
            <Bubble.Description>{tld}</Bubble.Description>
            <S.Price>
                {pricingData?.price ? `${getDefaultCurrencySymbol()}${pricingData.price}` : <Loader />}
                {isSuccess && '/yr'}
            </S.Price>
        </S.CompoundBubble>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Form } from 'formik'
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { InputPrefabStandard } from 'containers/shop/components/prefab/input.prefab.standard'
import { Button, Dropdown, Theme } from 'nxui/src'

/**********************************************************************************************************
 *   INTERFACES / ENUMS
 **********************************************************************************************************/
import { Weight } from 'models/enums'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
export const Result = {
    Base: styled.div`
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 15px;
        border-bottom: 1px solid ${(props) => props.theme['border--100']};
        line-break: anywhere;

        &:last-of-type {
            border-bottom: 0;
        }

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            flex-flow: column nowrap;
            padding: 10px;
        }
    `,
    Row: styled.div`
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        line-break: anywhere;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            width: 100%;
            margin-bottom: 10px;
            gap: 5px;

            &:last-of-type {
                width: 100%;
                margin-bottom: 0;
            }
        }
    `,
    Domain: styled.div`
        display: flex;
    `,
    Title: styled.div`
        width: 100%;
        max-width: 550px;
        height: fit-content;
        font-size: 1.4rem;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
    `,
    TLD: styled.span`
        height: fit-content;
        font-size: 1.4rem;
        color: ${(props) => props.theme['text--300']};
        ${Theme.Functions.setFont('Inter', Weight.Bold)};
    `,
    Status: styled.div`
        height: fit-content;
        width: fit-content;
        padding: 0 5px;
    `,
    Price: styled.div`
        display: inline-flex;
        justify-content: flex-end;
        margin: 0 5px;

        > div {
            width: 212px;
        }

        del {
            margin-right: 3px;
            opacity: 0.6;
        }

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            margin: 0;
        }
    `,
    Action: styled.div`
        display: inline-flex;
        justify-content: flex-end;
        height: fit-content;
        width: 100%;
        max-width: 115px;
        min-width: 100px;
        padding: 0 0 0 5px;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            width: 100%;
            max-width: unset;
            margin-left: -22px;
        }
    `,
    BillingCycle: styled(Dropdown)`
        margin-bottom: 0;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            margin: 0;
        }
    `,
    TooltipWrapper: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
    `
}

export const Register = {
    Base: styled.div``,
    LoaderContainer: styled.div`
        padding: 20px 0;
    `,
    Fields: styled.div`
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        width: 100%;
        gap: 5px;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            width: 100%;
            gap: 0 5px;
            flex-direction: column;
        }
    `,
    Form: styled.form`
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        width: 100%;
        padding: 20px;
        gap: 0 5px;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            flex-flow: column;
        }
    `,
    Search: styled.div`
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        width: 100%;
        padding: 20px;
        gap: 0 5px;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            flex-flow: column;
        }
    `,
    Subtitle: styled.h2`
        margin: 0 20px 10px;
        letter-spacing: -0.5px;
        font-size: 1.8rem;
        border-bottom: 2px solid transparent;
        color: ${(props) => Theme.Functions.hexToRGBA(props.theme['text--300'], '0.8')};
        ${Theme.Functions.setFont('Inter', Weight.Bold)};
    `,
    Description: styled.p`
        margin: 0 20px 10px;
        letter-spacing: -0.5px;
        font-size: 1.4rem;
        border-bottom: 2px solid transparent;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
    `,
    DomainField: styled(InputPrefabStandard)``,
    DomainTLD: styled.div`
        max-width: 150px;
        width: 100%;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            max-width: none;
        }
    `,
    DomainSubmit: styled(Button)`
        white-space: nowrap;
        margin-left: 0;
        margin-bottom: 10px;
        justify-content: center;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
            width: 100%;
        }
    `
}

export const Registration = {
    Form: styled(Form)`
        width: 100%;
    `,
    Dock: styled.div`
        padding-top: 15px;
    `,
    Body: styled.div``,
    Description: styled.p`
        margin: 20px 20px 10px;
        letter-spacing: -0.5px;
        font-size: 1.4rem;
        border-bottom: 2px solid transparent;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
    `,
    Note: styled.p<{ noTopMargin?: boolean }>`
        padding: 20px;
        margin: ${({ noTopMargin }) => (noTopMargin ? '0 20px 10px 20px' : '20px 20px 10px 20px')};
        letter-spacing: -0.5px;
        font-size: 1.4rem;
        text-align: center;
        border-radius: 8px;
        border: 1px solid ${(props) => Theme.Functions.hexToRGBA(props.theme['warning--100'], '0.4')};
        color: ${(props) => props.theme['text--100']};
        background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme['warning--100'], '0.1')};
        ${Theme.Functions.setFont('Inter', Weight.Regular)};

        strong {
            ${Theme.Functions.setFont('Inter', Weight.Bold)};
        }
    `,
    Section: styled.div`
        width: 100%;
        padding: 1rem;

        ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-sm'])} {
            padding: 2rem;
        }
    `,
    Row: styled.div`
        display: flex;
        flex-direction: column;
        gap: 5px;

        > div {
            width: 100%;
        }

        ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-sm'])} {
            flex-direction: row;
        }
    `,
    Checkbox: styled.div``,
    Footer: styled.footer`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 6px;
        padding: 10px 20px;
        height: 68px;
        background: ${(props) => props.theme['background--100']};
        border-radius: 0 0 16px 16px;
    `,
    Disable: styled(Button)`
        cursor: default;
        color: ${(props) => props.theme['text--100']};
        background-color: ${(props) => props.theme['gray--100']};

        &:hover {
            color: ${(props) => props.theme['text--100']};
            background-color: ${(props) => props.theme['gray--100']};
        }
    `,
    ConfigurationLoaderWrapper: styled.div`
        padding-left: 10px;
        margin-right: -10px;
    `,
    Title: styled.div<{ top?: boolean }>`
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: ${(props) => props.theme['text--200']};
        font-size: 1.4rem;
        padding: 2rem;
        background-color: ${(props) => props.theme['background--100']};
        ${Theme.Functions.setFont('Inter', Weight.Bold)};

        > span > div {
            position: absolute;
            width: fit-content;
            right: 0.5rem;
            top: 0.75rem;
            margin: 0;

            > div > label {
                background-color: transparent;
                ${Theme.Functions.setFont('Inter', Weight.Bold)};

                &:hover {
                    background-color: transparent;
                }

                > input {
                    top: 1px;
                }
            }
        }

        ${(props) =>
            props.top &&
            css`
                overflow: hidden;
            `};
    `,
    Text: styled.p`
        color: ${(props) => props.theme['text--200']};
        ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
        display: flex;
        gap: 8px;
    `,
    Highlight: styled.span`
        color: ${(props) => props.theme['gradient-primary--100']};
    `,
    Switch: {
        Field: styled.div`
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 15px;
        `,
        Label: styled.label`
            width: fit-content;
            margin-right: 15px;
            font-size: 1.2rem;
            color: ${(props) => props.theme['text--200']};
            ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
        `
    }
}

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Formik } from 'formik'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Button, Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORTS
 **********************************************************************************************************/
import PromotionCodes from 'containers/shop/checkout/promotion.codes'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Promotion } from 'containers/shop/shop.styles'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { shopAPI, useAddPromoCodeToCartMutation } from 'api/shop'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function PromotionForm() {
    const dispatch = useAppDispatch()
    const [addPromoCodeToCart] = useAddPromoCodeToCartMutation()
    const cartId = localStorage.getItem('cart_uuid')
    const { cart } = useAppSelector((state) => state.app)

    /*   RENDER COMPONENT
     *****************************************************/
    if (!cartId) return null

    return (
        <Promotion.Base>
            <Formik
                initialValues={{
                    promo: ''
                }}
                onSubmit={async (values, { resetForm }) => {
                    await addPromoCodeToCart({ uuid: cartId, code: values.promo })
                    dispatch(shopAPI.endpoints.viewCart.initiate(cartId, { forceRefetch: true }))
                    resetForm()
                }}
            >
                {({ values, isSubmitting }) => (
                    <Promotion.Form>
                        <Field name={'promo'} type={'text'} placeholder={'Promo Code'} disabled={isSubmitting} component={NXUIForm.InputField} />
                        <Button type={'submit'} disabled={isSubmitting || !cart?.items || values.promo.length === 0}>
                            Apply Code
                        </Button>
                    </Promotion.Form>
                )}
            </Formik>
            <PromotionCodes />
        </Promotion.Base>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

// TODO: make sure adding a promo code plays well with account credit applied

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { ReactElement } from 'react'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { IFieldLabel } from 'containers/shop/components/form.d'
import { Weight } from 'models/enums'

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Theme } from 'nxui/src'
import styled from 'styled-components'

export const Label = styled.label`
    position: relative;
    display: block;
    margin-bottom: 4px;
    font-size: 1.2rem;
    color: ${(props) => props.theme[`text--300`]};
    ${Theme.Functions.normalizeText()};
    ${Theme.Functions.animation('all', 200)};
    ${Theme.Functions.setFont(`'Inter', sans-serif`, Weight.SemiBold)};

    > span {
        margin-left: 4px;
        color: ${(props) => props.theme[`error--100`]};
    }
`

/**********************************************************************************************************
 *   RENDER INPUT ELEMENTS
 **********************************************************************************************************/
export const FieldLabel = (props: IFieldLabel): ReactElement => {
    const { label, embedded, name, required } = props
    return (
        <>
            {label && !embedded && (
                <Label htmlFor={name} {...props}>
                    {label}
                    {required && <span>*</span>}
                </Label>
            )}
        </>
    )
}

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useFormikContext } from 'formik'
import React from 'react'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Registration } from 'containers/shop/domain/domain.styles'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { CustomField } from './customField'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { checkIsEligibilityTypeCitizenResident } from '../helpers'

/**********************************************************************************************************
 *   INTERFACE / ENUMS
 **********************************************************************************************************/
import { ICustomFieldGroup } from 'models/shop/product'
import { TDomainConfigurationFormValues } from '../domain.configure'

type TCustomFieldGroups = React.FC<{
    group: Readonly<ICustomFieldGroup>
    children?: React.ReactNode
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CustomFieldGroups: TCustomFieldGroups = ({ group, children }) => {
    const mutableCustomFieldInputs = [...group.custom_field_inputs]

    /***** HOOKS *****/
    const { values } = useFormikContext<TDomainConfigurationFormValues>()

    /***** RENDER *****/
    if (mutableCustomFieldInputs.length === 0) return null

    const isEligibilityTypeCitizenResident = checkIsEligibilityTypeCitizenResident(values, group)

    return (
        <>
            <Registration.Title>{group.name}</Registration.Title>
            {children}
            <Registration.Section>
                {mutableCustomFieldInputs
                    .sort(({ order }, b) => order - b.order)
                    .map((field, i) => (
                        <CustomField field={field} group={group} key={i} />
                    ))}
            </Registration.Section>
            {isEligibilityTypeCitizenResident ? (
                <Registration.Note noTopMargin>
                    To process this registration, you will be required to perform either a Driver's Licence, Passport or Medicare Card ID
                    verification. Once you have paid for your order, we will contact you via email to complete the required ID verification.
                </Registration.Note>
            ) : (
                ''
            )}
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

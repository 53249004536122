/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Formik, Form } from 'formik'
import { useNavigate } from 'react-router-dom'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { billingAPI } from 'api/billing'
import { useCheckoutCartMutation } from 'api/shop'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useApplyAccountCreditToInvoice } from 'containers/billing/utils'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function AccountCreditForm() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { cart } = useAppSelector((state) => state.app)
    const { checkoutAccountCredit } = useAppSelector((state) => state.shop)
    const { data: paymentMethods } = billingAPI.endpoints.paymentMethods.useQueryState()
    const firstPaymentMethod = paymentMethods?.find(({ id }) => id)
    const [checkoutCart] = useCheckoutCartMutation({ fixedCacheKey: 'checkout-cart' })
    const { applyAccountCreditToInvoice } = useApplyAccountCreditToInvoice()

    /*   RENDER COMPONENT
     *****************************************************/
    if (!cart || !firstPaymentMethod) return null

    return (
        <Formik
            initialValues={{}}
            onSubmit={async () => {
                const order = await checkoutCart({
                    payment_method_id: firstPaymentMethod.id,
                    uuid: cart.uuid
                }).unwrap()

                await applyAccountCreditToInvoice(order.invoice.id, checkoutAccountCredit.amount)

                dispatch(billingAPI.endpoints.invoice.initiate(order.invoice.id, { forceRefetch: true })).unwrap()
                navigate(`/shop/order/${order.id}`)
            }}
        >
            {() => <Form id='accountCreditForm' />}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

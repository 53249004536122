/**********************************************************************************************************
 *   INTERFACE / ENUMS
 **********************************************************************************************************/
import { ICartItem } from 'models/shop/cart'
import { IAddon, ICustomFieldGroup } from 'models/shop/product'

type TGenerateCustomFieldInitialValues = (props: { customFieldGroups?: Array<ICustomFieldGroup>; cartItem: ICartItem }) => Array<{
    id: number
    value: string | boolean
}>

type TGenerateAddonInitialValues = (props: { cartItem: ICartItem; addons?: Array<IAddon> }) => Array<{
    product_id: number
    billing_cycle_id: number | null
    config_options: Array<{
        config_id: number
        option_id: string | number
        quantity: Array<string> | number
    }>
}>

type TGenerateConfigOptionsInitialValues = (props: { cartItem: ICartItem }) => Array<{
    config_id: number
    option_id: string | number
    quantity: Array<string> | number
}>

type TGenerateInitialValues = (props: { cartItem: ICartItem; addons?: Array<IAddon>; customFieldGroups?: Array<ICustomFieldGroup> }) => {
    addons: ReturnType<TGenerateAddonInitialValues>
    config_options: ReturnType<TGenerateConfigOptionsInitialValues>
    custom_fields: ReturnType<TGenerateCustomFieldInitialValues>
    billing_cycle_id: number
    domain: string
}

export const generateCustomFieldInitialValues: TGenerateCustomFieldInitialValues = ({ customFieldGroups, cartItem }) => {
    // make sure that custom_fields is always at least an array
    if (!customFieldGroups) return []

    return customFieldGroups.flatMap((group) => {
        const customFieldValues = group.custom_field_inputs
            // Do not show fields that are only for admins
            .filter(({ admin_only }) => !admin_only)
            .map((customFieldInput) => {
                const existingCustomFieldInput = cartItem?.custom_fields?.find(({ id }) => id === customFieldInput.id)

                // Convert to truthy switch when typescript 5.4 is installed
                if (existingCustomFieldInput?.input?.field_type === 'checkbox') {
                    return {
                        id: existingCustomFieldInput.id,
                        value: existingCustomFieldInput.value === '1'
                    }
                }

                if (existingCustomFieldInput) {
                    return {
                        id: existingCustomFieldInput.id,
                        value: existingCustomFieldInput.value
                    }
                }

                if (customFieldInput.field_type === 'dropdown' && customFieldInput.field_options) {
                    return {
                        id: customFieldInput.id,
                        value: customFieldInput.field_options.split(',')[0]
                    }
                }

                if (customFieldInput.field_type === 'checkbox') {
                    return {
                        id: customFieldInput.id,
                        value: false
                    }
                }

                return { id: customFieldInput.id, value: '' }
            })

        return customFieldValues
    })
}

export const generateAddonInitialValues: TGenerateAddonInitialValues = ({ cartItem, addons }) => {
    if (!cartItem || !addons) return []

    return addons?.map((addon) => ({
        product_id: addon.id,
        billing_cycle_id: cartItem?.billing_cycle.id ?? null,
        config_options:
            addon.config_option_groups?.flatMap((configOptionGroup) => {
                return configOptionGroup.config_options.flatMap((configOption) => ({
                    config_id: configOption.id,
                    option_id: configOption.input_type === 'radio' ? String(configOption.values[0].id) : configOption.values[0].id,
                    quantity: configOption.input_type === 'checkbox' && configOption ? ['1'] : 1
                }))
            }) ?? []
    }))
}

export const generateConfigOptionsInitialValues: TGenerateConfigOptionsInitialValues = ({ cartItem }) => {
    if (!cartItem?.config_options) return []

    return cartItem.config_options?.map((configOption) => ({
        config_id: configOption.config.id,
        option_id: configOption.config.input_type === 'radio' ? String(configOption.option.id) : configOption.option.id,
        ...(configOption.config.input_type === 'checkbox' && configOption.quantity ? { quantity: ['1'] } : { quantity: [] }),
        ...(configOption.config.input_type === 'number' && { quantity: configOption.quantity })
    }))
}

export const generateInitialValues: TGenerateInitialValues = ({ cartItem, addons, customFieldGroups }) => ({
    addons: generateAddonInitialValues({ cartItem, addons }),
    config_options: generateConfigOptionsInitialValues({ cartItem }),
    custom_fields: generateCustomFieldInitialValues({ cartItem, customFieldGroups }),
    billing_cycle_id: cartItem.billing_cycle.id,
    domain: cartItem.name ?? ''
})

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { ReactElement } from 'react'

/**********************************************************************************************************
 *   INTERFACE/TYPES
 *********************************************************************************************************/
export interface Option {
    label?: string
    value?: string | number | ReadonlyArray<string> | undefined
}

interface IFieldDatalist {
    name: string
    options: Array<Option>
}

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled from 'styled-components'

const Field = {
    Datalist: styled.datalist``
}

/**********************************************************************************************************
 *   RENDER INPUT
 **********************************************************************************************************/
export const FieldDatalist = (props: IFieldDatalist): ReactElement => {
    return (
        <Field.Datalist id={props.name}>
            {props.options.map((option: Option, index: number) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
            ))}
        </Field.Datalist>
    )
}

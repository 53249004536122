/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Form, Formik } from 'formik'
import { useEffect } from 'react'
import images from 'react-payment-inputs/images'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Loader } from 'components/loader'
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORTS
 **********************************************************************************************************/
import { AccountCreditForm } from 'containers/shop/checkout/accountCredit.form'
import { CreditCardForm } from 'containers/shop/checkout/credit.card'
import { DefaultPaymentMethodForm } from 'containers/shop/checkout/default.method'
import { EftForm } from 'containers/shop/checkout/eft.form'
import { LinkTypeForm } from 'containers/shop/checkout/linkType.form'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Method, Payment } from 'components/invoice/invoice.styles'
import { AccountCredit } from 'containers/billing/billing.styles'
import { Checkout } from 'containers/shop/shop.styles'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useAccountCreditQuery } from 'api/billing'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setCheckoutAccountCredit, setCheckoutPaymentFormData } from 'store/slices/shopSlice'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { usePaymentMethods } from 'components/invoice/hooks/usePaymentMethods'
import { AccountCreditDebounce } from 'containers/billing/utils'
import { TokenTypeForm } from 'containers/shop/checkout/tokenTypeForm'
import { defaultCurrency, getCreditCardImage, getDefaultCurrencySymbol, getNumberFromStringWithCommas, parseISO } from 'helpers/utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function PaymentMethods() {
    const dispatch = useAppDispatch()
    const { cart, appSession } = useAppSelector((state) => state.app)
    const { checkoutAccountCredit } = useAppSelector((state) => state.shop)

    const { accountCredit, isLoading: isAccountCreditLoading } = useAccountCreditQuery(undefined, {
        skip: !appSession.authenticated,
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            accountCredit: data?.account_credit
        })
    })

    // Get the full list of available payment methods including default / saved method
    const {
        paymentMethods,
        selectedPaymentMethod,
        setSelectedPaymentMethodId,
        savedPaymentMethods,
        selectedSavedPaymentMethod,
        setSelectedSavedPaymentMethodId,
        isFetching: isPaymentMethodsFetching
    } = usePaymentMethods()

    function getCheckoutPaymentMethodFormName() {
        if (selectedSavedPaymentMethod) return 'savedPaymentMethodForm'
        return selectedPaymentMethod?.formName
    }

    useEffect(() => {
        dispatch(
            setCheckoutPaymentFormData({
                name: getCheckoutPaymentMethodFormName(),
                hasCapture: !!selectedPaymentMethod?.module_meta && selectedPaymentMethod?.module_meta?.capture_type !== 'none'
            })
        )
    }, [selectedPaymentMethod, selectedSavedPaymentMethod])

    useEffect(() => {
        dispatch(setCheckoutAccountCredit({ amount: 0, isValid: true }))
    }, [])

    /*   RENDER COMPONENT
     *****************************************************/
    if (!cart?.items) return null

    if (isPaymentMethodsFetching || isAccountCreditLoading) {
        return (
            <Checkout.Loading>
                <Loader message={'Fetching Payment Methods...'} width={48} height={48} />
            </Checkout.Loading>
        )
    }

    const cartTotalDue = getNumberFromStringWithCommas(cart.pricing.total_due)

    const isPayingWithOnlyAccountCredit = !!(checkoutAccountCredit.amount && checkoutAccountCredit.amount >= cartTotalDue) || !cartTotalDue
    const isAccountCreditGreaterThanTotalDue = Number(accountCredit) > cartTotalDue

    return (
        <Checkout.Payment>
            <Payment.Header>
                <Payment.Content>
                    <Payment.Title>Payment Method</Payment.Title>
                    <Payment.Description>Complete your purchase by providing your payment details.</Payment.Description>
                </Payment.Content>
                {accountCredit && accountCredit !== '0.00' ? (
                    <Formik
                        initialValues={{
                            accountCreditAmount: '0.00',
                            applyAccountCredit: false
                        }}
                        validationSchema={Yup.object().shape({
                            accountCreditAmount: Yup.number()
                                .min(0, 'Please enter a positive number.')
                                .max(
                                    isAccountCreditGreaterThanTotalDue ? cartTotalDue : Number(accountCredit),
                                    isAccountCreditGreaterThanTotalDue
                                        ? 'Amount exceeds total due today.'
                                        : 'Amount exceeds current account credit balance.'
                                )
                        })}
                        onSubmit={async () => {
                            //
                        }}
                    >
                        {({ setFieldValue, values }) => {
                            return (
                                <Form>
                                    <AccountCredit.Container>
                                        <NXUIForm.CurrencyField
                                            label={`Apply Account Credit (${defaultCurrency(accountCredit)})`}
                                            disabled={!values.applyAccountCredit}
                                            name={'accountCreditAmount'}
                                            prefix={getDefaultCurrencySymbol()}
                                        />
                                        <NXUIForm.Switch
                                            disabled={!cartTotalDue}
                                            checked={values.applyAccountCredit}
                                            onClick={() => {
                                                function getNewCreditFieldValue() {
                                                    if (values.applyAccountCredit) return '0.00'
                                                    if (Number(accountCredit) < cartTotalDue) {
                                                        return accountCredit
                                                    }
                                                    return cart?.pricing.total_due
                                                }

                                                setFieldValue('accountCreditAmount', getNewCreditFieldValue())
                                                setFieldValue('applyAccountCredit', !values.applyAccountCredit)
                                            }}
                                        />
                                    </AccountCredit.Container>
                                    <AccountCreditDebounce
                                        onStopTyping={(values, isValid) => {
                                            dispatch(setCheckoutAccountCredit({ amount: Number(values.accountCreditAmount), isValid }))
                                        }}
                                    />
                                </Form>
                            )
                        }}
                    </Formik>
                ) : (
                    ''
                )}
            </Payment.Header>
            <Payment.Body>
                <Method.Wrapper aria-label={'Tabs'}>
                    {savedPaymentMethods.length > 0 ? (
                        <Method.Option.Wrapper
                            type='button'
                            className={`${selectedSavedPaymentMethod ? 'active' : ''}${isPayingWithOnlyAccountCredit ? ' disabled' : ''}`}
                            onClick={() => {
                                if (isPayingWithOnlyAccountCredit) return
                                setSelectedSavedPaymentMethodId()
                            }}
                        >
                            <Method.Option.Label>Saved Payment Methods</Method.Option.Label>
                        </Method.Option.Wrapper>
                    ) : (
                        ''
                    )}

                    {selectedSavedPaymentMethod && !isPayingWithOnlyAccountCredit
                        ? savedPaymentMethods.map(({ id, is_default, identifier, type, expiry }) => {
                              return (
                                  <DefaultPaymentMethodForm key={id} selectedSavedPaymentMethod={selectedSavedPaymentMethod}>
                                      <Method.NestedContent>
                                          <Method.Option.Wrapper
                                              type='button'
                                              className={`${selectedSavedPaymentMethod?.id === id ? 'active' : ''}${
                                                  isPayingWithOnlyAccountCredit ? ' disabled' : ''
                                              }`}
                                              aria-current={selectedPaymentMethod?.name === name ? 'page' : undefined}
                                              onClick={() => {
                                                  if (isPayingWithOnlyAccountCredit) return
                                                  setSelectedSavedPaymentMethodId(id)
                                              }}
                                          >
                                              {is_default ? (
                                                  <Method.Saved.BadgeBackground>
                                                      <Method.Saved.Badge color='primary'>default</Method.Saved.Badge>
                                                  </Method.Saved.BadgeBackground>
                                              ) : (
                                                  ''
                                              )}
                                              <Method.Saved.Type>
                                                  <svg {...images[getCreditCardImage(type)].props} />
                                              </Method.Saved.Type>
                                              <Method.Saved.Card>**** **** **** {identifier}</Method.Saved.Card>
                                              {expiry ? (
                                                  <Method.Saved.Expiry>{`${parseISO(expiry).month}/${parseISO(expiry).year}`}</Method.Saved.Expiry>
                                              ) : (
                                                  ''
                                              )}
                                              {isPayingWithOnlyAccountCredit ? (
                                                  ''
                                              ) : (
                                                  <Method.Option.Icon.Fade when={selectedSavedPaymentMethod}>
                                                      {selectedSavedPaymentMethod?.id === id ? (
                                                          <Method.Option.Icon.Wrapper>
                                                              <Method.Option.Icon.Check />
                                                          </Method.Option.Icon.Wrapper>
                                                      ) : (
                                                          ''
                                                      )}
                                                  </Method.Option.Icon.Fade>
                                              )}
                                          </Method.Option.Wrapper>
                                      </Method.NestedContent>
                                  </DefaultPaymentMethodForm>
                              )
                          })
                        : ''}

                    {paymentMethods.map(({ name, id }) => {
                        const isSelected = selectedPaymentMethod?.name === name
                        const isDisabled = isPayingWithOnlyAccountCredit

                        return (
                            <div key={id}>
                                <Method.Option.Wrapper
                                    type='button'
                                    key={id}
                                    className={`${isSelected ? 'active' : ''}${isDisabled ? ' disabled' : ''}`}
                                    aria-current={isSelected ? 'page' : undefined}
                                    onClick={() => {
                                        if (isDisabled) return
                                        setSelectedPaymentMethodId(id)
                                    }}
                                >
                                    <Method.Option.Label>{name}</Method.Option.Label>
                                    {isDisabled ? (
                                        ''
                                    ) : (
                                        <Method.Option.Icon.Fade when={selectedPaymentMethod}>
                                            {isSelected && (
                                                <Method.Option.Icon.Wrapper>
                                                    <Method.Option.Icon.Check />
                                                </Method.Option.Icon.Wrapper>
                                            )}
                                        </Method.Option.Icon.Fade>
                                    )}
                                </Method.Option.Wrapper>
                                {isSelected && !isDisabled ? (
                                    <Method.NestedContent>
                                        <Method.NestedForm>
                                            <Payment.Fade hidden={isDisabled}>
                                                {(() => {
                                                    switch (selectedPaymentMethod?.formName) {
                                                        case 'linkTypeForm':
                                                            return <LinkTypeForm selectedPaymentMethod={selectedPaymentMethod} />
                                                        case 'eftForm':
                                                            return <EftForm selectedPaymentMethod={selectedPaymentMethod} />
                                                        case 'creditCardForm':
                                                            return <CreditCardForm selectedPaymentMethod={selectedPaymentMethod} />
                                                        case 'tokenTypeForm':
                                                            return <TokenTypeForm selectedPaymentMethodId={selectedPaymentMethod.id} />
                                                        default:
                                                            return ''
                                                    }
                                                })()}
                                            </Payment.Fade>
                                        </Method.NestedForm>
                                    </Method.NestedContent>
                                ) : (
                                    ''
                                )}
                            </div>
                        )
                    })}
                </Method.Wrapper>

                {isPayingWithOnlyAccountCredit ? <AccountCreditForm /> : ''}
            </Payment.Body>
        </Checkout.Payment>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

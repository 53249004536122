/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { UserPlusIcon, UsersIcon } from '@heroicons/react/24/outline'

/**********************************************************************************************************
 *   SHARED COMPONENTS
 **********************************************************************************************************/
import { Button, Theme } from 'nxui/src'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
import styled from 'styled-components'

export const Landing = {
    Base: styled.div`
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 20px;
        min-height: 250px;
    `,
    Break: styled.div`
        position: relative;
        display: flex;
        height: 100%;
        width: 1px;
        border-left: 1px solid ${(props) => props.theme['border--100']};
    `,
    Wrapper: styled.div`
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        padding: 20px;
    `,
    Info: {
        Base: styled.div`
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            min-height: 140px;
        `,
        Title: styled.h2`
            margin-top: 20px;
        `,
        Description: styled.p`
            text-align: center;
        `,
        Action: styled(Button)`
            margin-top: 30px;
            width: 125px;
            justify-content: center;
        `
    },
    Existing: {
        Base: styled.div`
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            width: 50%;
            min-height: 150px;
            padding: 20px;
            border-right: 1px solid ${(props) => props.theme['border--100']};

            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-md'])} {
                border-bottom: 1px solid ${(props) => props.theme['border--100']};
                border-right: 0;
            }
        `,
        Icon: styled(UsersIcon)`
            width: 48px;
            color: ${(props) => props.theme['icon--100']};
        `
    },
    New: {
        Base: styled.div`
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: flex-start;
            width: 50%;
            min-height: 150px;
            padding: 20px;
        `,
        Icon: styled(UserPlusIcon)`
            width: 48px;
            color: ${(props) => props.theme['icon--100']};
        `
    }
}

/**********************************************************************************************************
 *   INTERFACE / ENUMS
 **********************************************************************************************************/
import { ICustomFieldGroup } from 'models/shop/product'
import { TDomainConfigurationFormValues } from './domain.configure'

export function checkIsEligibilityTypeCitizenResident(formValues: TDomainConfigurationFormValues, customFieldGroup?: Readonly<ICustomFieldGroup>) {
    const eligibilityTypeFieldId = customFieldGroup?.custom_field_inputs.find((field) => field.field_name === 'Eligibility Type')?.id

    return formValues.custom_fields.some((field) => field.id === eligibilityTypeFieldId && field.value === 'Citizen/Resident')
}

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

/**********************************************************************************************************
 *   STORE IMPORTS
 **********************************************************************************************************/
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setActiveProductGroup } from 'store/slices/shopSlice'

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useProductGroupsQuery } from 'api/shop'

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FrameInset, Loader } from 'nxui/src'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { Shop } from '../shop.styles'
import { ShopSummary } from '../shop.summary'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { IProductGroup } from 'models/shop/product'

const DomainContent = {
    type: 'domains',
    name: 'Domain Names',
    description: null
}

type TShopPurchaseTemplate = React.FC<{
    browserTitle: React.ReactNode
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ShopPurchaseTemplate: TShopPurchaseTemplate = ({ browserTitle }) => {
    /***** HOOKS *****/
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const settingsSections = useAppSelector((state) => state.app.appSettings.section)
    const { activeProductGroup } = useAppSelector((state) => state.shop)

    /***** QUERIES *****/
    const { data: productGroupData, isLoading: isProductGroupDataLoading } = useProductGroupsQuery()

    /***** RENDER *****/
    return (
        <FrameInset
            inset={'full'}
            content={{
                render: (
                    <Shop.Base>
                        <Shop.Title>Shop</Shop.Title>
                        <Shop.Tabs>
                            {!!settingsSections.shop.domains && (
                                <Shop.Tab
                                    active={activeProductGroup.name === 'Domain Names'}
                                    onClick={() => {
                                        if (location.pathname.includes('/configure')) {
                                            navigate('/shop/purchase')
                                        }

                                        dispatch(setActiveProductGroup(DomainContent))
                                    }}
                                >
                                    Domain Names
                                </Shop.Tab>
                            )}
                            {!!settingsSections.shop.products &&
                                productGroupData?.map((group: IProductGroup, index: number) => (
                                    <Shop.Tab
                                        key={index}
                                        active={activeProductGroup.name === group.name}
                                        onClick={() => {
                                            if (location.pathname.includes('/configure')) {
                                                navigate('/shop/purchase')
                                            }

                                            dispatch(setActiveProductGroup(group))
                                        }}
                                    >
                                        {group.name}
                                    </Shop.Tab>
                                ))}
                            {isProductGroupDataLoading && (
                                <Shop.TabLoader>
                                    <Loader.Basic />
                                </Shop.TabLoader>
                            )}
                        </Shop.Tabs>
                        <Shop.Content>
                            <div>
                                {browserTitle}
                                <Shop.Browse.Base>
                                    <Shop.Browse.Content>
                                        <Outlet />
                                    </Shop.Browse.Content>
                                </Shop.Browse.Base>
                            </div>
                            <ShopSummary />
                        </Shop.Content>
                    </Shop.Base>
                )
            }}
        />
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

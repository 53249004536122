/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { CheckIcon, PlusIcon } from '@heroicons/react/24/outline'
import { FormikProps } from 'formik'
import { RefObject, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Loader } from 'components/loader'
import { Anchor, Badge, Button } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import DomainSearch from 'containers/shop/domain/domain.search'
import { IServiceConfigurationForm } from 'containers/shop/services/configure'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useCheckDomainAvailabilityMutation, useCreateCartMutation, useRemoveCartItemMutation, useUpdateCartItemsMutation } from 'api/shop'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { defaultCurrency, getDomainName, sleep } from 'helpers/utils'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Result } from 'containers/shop/domain/domain.styles'
import { Services } from 'containers/shop/shop.styles'

/**********************************************************************************************************
 *   INTERFACE / ENUMS
 **********************************************************************************************************/
import { OrderType } from 'models/enums'
import { ICartCreateItem } from 'models/shop/cart'
import { IDomainAvailability } from 'models/shop/domain'
import { IPricing } from 'models/shop/shop'

interface Props {
    embedded: boolean
    serviceConfigRef?: RefObject<FormikProps<IServiceConfigurationForm>>
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function DomainTransfer({ embedded = false, serviceConfigRef }: Props) {
    /***** STATE *****/
    const [selectedPricing, setSelectedPricing] = useState<Array<{ id: number; selectedPrice: IPricing }>>([])

    /***** HOOKS *****/
    const navigate = useNavigate()
    const { searchTerm } = useAppSelector((state) => state.shop)
    const { cart } = useAppSelector((state) => state.app)
    const domain = getDomainName(searchTerm)

    /***** QUERIES *****/
    const [createCart, { isLoading: isCreateCartLoading }] = useCreateCartMutation()
    const [updateCartItems, { isLoading: isUpdateCartItemsLoading }] = useUpdateCartItemsMutation()
    const [removeCartItem, { isLoading: isRemoveCartItemLoading }] = useRemoveCartItemMutation()
    const [, { data: availabilityData, isLoading: isAvailabilityLoading, reset }] = useCheckDomainAvailabilityMutation({
        fixedCacheKey: 'check-domain-availability'
    })

    /***** FUNCTIONS *****/
    function getPricingDropdown({ id, name, pricing, premium }: { id: number; name: string; pricing: Array<IPricing>; premium: boolean }) {
        const transferPrices = pricing.filter((price) => price.type === 'transfer')

        const optionsList = transferPrices.map((item: IPricing) => {
            if (premium) {
                return {
                    label: `${defaultCurrency(item.cost)} / ${item.billing_cycle.name}`,
                    value: item.cost,
                    onClick: () => setSelectedPricing([...selectedPricing, { id, selectedPrice: item }])
                }
            }

            return {
                label: `${defaultCurrency(item.price)} / ${item.billing_cycle.name}`,
                value: item.price,
                onClick: async () => {
                    const cartItem = cart?.items?.find((cartItem) => cartItem.name === name)

                    if (cartItem) {
                        await updateCartItems({
                            uuid: cart?.uuid,
                            items: [
                                {
                                    item_uuid: cartItem.item_uuid,
                                    pending: true,
                                    order_type: 'transfer',
                                    product_id: cartItem.product.id,
                                    billing_cycle_id: item.billing_cycle_id,
                                    name: cartItem.name
                                }
                            ]
                        })
                    } else {
                        setSelectedPricing([...selectedPricing, { id, selectedPrice: item }])
                    }
                }
            }
        })

        const options = optionsList.length === 0 ? [{}] : optionsList

        return <Result.BillingCycle id={`${id}-pricing`} name={`${id}-pricing`} options={options} />
    }

    async function processDomainSelection() {
        if (!availabilityData) return

        const {
            product: { name: tld },
            pricing
        } = availabilityData
        const { 0: firstPrice } = pricing.filter((price) => price.type === 'transfer')
        const item: ICartCreateItem = {
            pending: true,
            order_type: 'transfer',
            product_id: availabilityData.product.id,
            billing_cycle_id: selectedPricing.length >= 1 ? selectedPricing[0].selectedPrice.billing_cycle_id : firstPrice.billing_cycle_id,
            name: `${domain}${tld}`
        }

        if (cart?.uuid) {
            const cartItem = cart?.items?.find(({ name }) => name === `${domain}${tld}`)

            if (cartItem) {
                return navigate(`/shop/configure/domains/${cartItem.item_uuid}`)
            }

            const result = await updateCartItems({ uuid: cart?.uuid, items: [item] }).unwrap()
            const pendingItem = result.items?.find(({ name }) => name === `${domain}${tld}`)

            if (pendingItem && !embedded) {
                navigate(`/shop/configure/domains/${pendingItem.item_uuid}`)
            }

            if (serviceConfigRef?.current && embedded) {
                serviceConfigRef.current.setFieldValue('domain', `${domain}${tld}`)
                await sleep(100)
                serviceConfigRef.current.handleSubmit()
            }
        } else {
            await createCart({ items: [item] })
        }
    }

    /***** RENDER HELPERS *****/
    function renderSelectDomain(item: IDomainAvailability) {
        const cartItem = cart?.items?.find(
            (pendingCartItem) => pendingCartItem.product.id === item.product.id && pendingCartItem.name === `${domain}${item.product.name}`
        )

        if (cart && cartItem) {
            return (
                <Anchor
                    color={'select'}
                    disabled={isRemoveCartItemLoading}
                    icon={<CheckIcon />}
                    onClick={() => removeCartItem({ uuid: cart.uuid, remove_items: [cartItem.item_uuid] })}
                >
                    Selected
                </Anchor>
            )
        }

        return (
            <Anchor color={'select'} disabled={isCreateCartLoading || isUpdateCartItemsLoading} icon={<PlusIcon />} onClick={processDomainSelection}>
                Select
            </Anchor>
        )
    }

    function renderPricing(availabilityData: IDomainAvailability) {
        const {
            product: { id, name: tld },
            premium,
            pricing
        } = availabilityData

        if (embedded) {
            return (
                <Result.Row>
                    <Result.Price>{getPricingDropdown({ id, pricing, premium, name: `${domain}${tld}` })}</Result.Price>
                    {embedded && <Result.Action>{renderSelectDomain(availabilityData)}</Result.Action>}
                </Result.Row>
            )
        }

        return (
            <div>
                <Result.Price>{getPricingDropdown({ id, pricing, premium, name: `${domain}${tld}` })}</Result.Price>
                {embedded && <Result.Action>{renderSelectDomain(availabilityData)}</Result.Action>}
            </div>
        )
    }

    function getAvailabilityBanner() {
        if (isAvailabilityLoading) {
            return (
                <Services.Loading>
                    <Loader width={45} height={45} message={'Checking Domain Availability...'} />
                </Services.Loading>
            )
        }

        if (!availabilityData) return null

        const hasTransferPricing = availabilityData.pricing.filter(({ type }) => type === 'transfer').length

        if (!availabilityData.availability && hasTransferPricing) {
            const {
                product: { name: tld },
                premium
            } = availabilityData

            return (
                <>
                    <Services.Check color={'confirm'}>Great news, {searchTerm} is available for transfer!</Services.Check>
                    <Services.Results>
                        <Result.Base>
                            <Result.Row>
                                <Result.Domain>
                                    <Result.Title>
                                        {domain}
                                        <Result.TLD>{tld}</Result.TLD>
                                    </Result.Title>
                                    <Result.Status>
                                        <Badge color={'confirm'}>Available</Badge>
                                    </Result.Status>
                                    {premium && (
                                        <Result.Status>
                                            <Badge color={'primary'}>Premium</Badge>
                                        </Result.Status>
                                    )}
                                </Result.Domain>
                            </Result.Row>
                            {renderPricing(availabilityData)}
                        </Result.Base>
                    </Services.Results>
                </>
            )
        }

        return <Services.Check color={'error'}>{searchTerm} is unavailable for transfer.</Services.Check>
    }

    /***** EFFECTS *****/
    useEffect(() => {
        reset()
    }, [])

    /***** RENDER *****/
    return (
        <>
            <div>
                <Services.Subtitle>Transfer a Domain Name</Services.Subtitle>
                <Services.Description>Move an existing domain name from another provider to your account here.</Services.Description>
                <DomainSearch embedded={embedded} type={OrderType.Transfer} />
                {getAvailabilityBanner()}
            </div>
            {!embedded && availabilityData && (
                <Services.Footer>
                    <Button
                        color={'primary'}
                        type={'button'}
                        disabled={isCreateCartLoading || isUpdateCartItemsLoading}
                        onClick={processDomainSelection}
                    >
                        Continue
                    </Button>
                </Services.Footer>
            )}
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { DomainTransfer as Transfer }

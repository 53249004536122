/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, useField, useFormikContext } from 'formik'
import { useEffect } from 'react'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { appAPI, useCountryStatesQuery } from 'api/app'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { useParams } from 'react-router-dom'
import { Registration } from '../../domain.styles'
import { useConfigureDomainsSelector } from '../hooks/selector'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PersonalFields = () => {
    /***** HOOKS *****/
    const { id } = useParams()
    const { cartItem } = useConfigureDomainsSelector(id)

    /***** FORM *****/
    const { isSubmitting, setFieldValue } = useFormikContext()
    const [{ value: state }] = useField('domain_contact_fields.state')
    const [{ value: country }] = useField('domain_contact_fields.country')

    /***** SELECTORS *****/
    const { authenticated } = useAppSelector((state) => state.app.appSession)
    const { default_country } = useAppSelector((state) => state.app.appSettings.provider)

    /***** QUERIES *****/
    const { data: countriesData } = appAPI.endpoints.countries.useQuery({ type: 'all' })
    const { states, status } = useCountryStatesQuery(country, {
        selectFromResult: ({ data, ...rest }) => ({
            ...rest,
            states:
                data?.map(({ name, iso_code }) => ({
                    label: name,
                    value: iso_code
                })) ?? []
        })
    })

    /***** EFFECTS *****/
    useEffect(() => {
        if (
            country !== cartItem?.domain_contact_fields?.country && // check that the country is changing from the initial country (cartItem)
            status === 'fulfilled' && // check that the states have finished refetching
            !states.map(({ value }) => value).includes(state) // check that the current state is not in the new list of states (if so it does not need to change)
        ) {
            // update the state value default if the country is changed (as most likely the available states have changed as well)
            setFieldValue('domain_contact_fields.state', states?.[0]?.value)
        }
    }, [country, status])

    /***** RENDER HELPERS *****/
    const countries =
        countriesData?.map(({ name, iso_code }) => ({
            label: name,
            value: iso_code
        })) ?? []

    /***** RENDER *****/
    return (
        <>
            <Registration.Title top={true}>
                <span>Domain Contact Details</span>
                {authenticated && (
                    <span>
                        <Field
                            id={'use_account_contact'}
                            name={'use_account_contact'}
                            type={'text'}
                            description={'Use My Account Details'}
                            disabled={isSubmitting}
                            component={NXUIForm.CheckboxField}
                        />
                    </span>
                )}
            </Registration.Title>
            <Registration.Description>
                This information is used to identify the owner of this domain, and is required by the domain registrar to complete the domain
                registration process. It is extremely important to provide up to date and valid domain contact information.
            </Registration.Description>
            <Registration.Section>
                <Registration.Row>
                    <Field
                        required
                        id={'firstname'}
                        label={'First Name'}
                        name={'domain_contact_fields.firstname'}
                        type={'text'}
                        autoComplete={'given-name'}
                        disabled={isSubmitting}
                        component={NXUIForm.InputField}
                    />
                    <Field
                        required
                        id={'lastname'}
                        label={'Last Name'}
                        name={'domain_contact_fields.lastname'}
                        type={'text'}
                        autoComplete={'family-name'}
                        disabled={isSubmitting}
                        component={NXUIForm.InputField}
                    />
                </Registration.Row>
                <Registration.Row>
                    <Field
                        required
                        id={'phone'}
                        label={'Phone Number'}
                        name={'domain_contact_fields.phone'}
                        type={'text'}
                        country={typeof default_country === 'string' ? default_country.toLowerCase() : 'au'}
                        autoFormat={false}
                        disabled={isSubmitting}
                        component={NXUIForm.PhoneField}
                    />
                    <Field
                        id={'organisation'}
                        label={'Company'}
                        name={'domain_contact_fields.organisation'}
                        type={'text'}
                        autoComplete={'organization-title'}
                        disabled={isSubmitting}
                        component={NXUIForm.InputField}
                    />
                </Registration.Row>
                <Field
                    required
                    id={'email'}
                    label={'Email Address'}
                    name={'domain_contact_fields.email'}
                    type={'text'}
                    autoComplete={'email'}
                    component={NXUIForm.InputField}
                />
                <Field
                    required
                    id={'address1'}
                    label={'Address Line 1'}
                    name={'domain_contact_fields.address1'}
                    type={'text'}
                    disabled={isSubmitting}
                    component={NXUIForm.InputField}
                />
                <Field
                    id={'address2'}
                    label={'Address Line 2'}
                    name={'domain_contact_fields.address2'}
                    type={'text'}
                    autoComplete={'address-line2'}
                    placeholder={'Apartment, suite, unit number, etc.'}
                    disabled={isSubmitting}
                    component={NXUIForm.InputField}
                />
                <Registration.Row>
                    <Field
                        required
                        id={'suburb'}
                        label={'Suburb'}
                        name={'domain_contact_fields.suburb'}
                        type={'text'}
                        autoComplete={'suburb'}
                        disabled={isSubmitting}
                        component={NXUIForm.InputField}
                    />
                    <Field
                        required
                        id={'postcode'}
                        label={'Postcode'}
                        name={'domain_contact_fields.postcode'}
                        type={'text'}
                        autoComplete={'postal-code'}
                        disabled={isSubmitting}
                        component={NXUIForm.InputField}
                    />
                </Registration.Row>
                <Registration.Row>
                    <Field
                        required
                        label={'Country/Region'}
                        name={'domain_contact_fields.country'}
                        type={'select'}
                        disabled={isSubmitting}
                        list={countries}
                        component={NXUIForm.SelectField}
                    />
                    <Field
                        required
                        label={'State/Province'}
                        name={'domain_contact_fields.state'}
                        type={'select'}
                        disabled={isSubmitting}
                        list={states}
                        component={NXUIForm.SelectField}
                    />
                </Registration.Row>
            </Registration.Section>
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Formik, FormikHelpers, FormikProps, useFormikContext } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Loader } from 'components/loader'
import { Button, Form as NXUIForm } from 'nxui/src'
import { CustomFieldGroups } from './components/customFieldGroup'
import { PersonalFields } from './components/personalFields'
import { ToggleFields } from './components/toggleFields'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useCheckDomainSuggestionsMutation, useCreateCartMutation, useDomainAvailabilityWithProductQuery, useUpdateCartItemsMutation } from 'api/shop'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { componentTransitionVariants, regex } from 'helpers/utils'
import { useAppSelector } from 'store/hooks'
import { checkIsEligibilityTypeCitizenResident } from './helpers'
import { useConfigureDomainsSelector } from './hooks/selector'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Registration } from 'containers/shop/domain/domain.styles'
import { Configure, Services } from 'containers/shop/shop.styles'

/**********************************************************************************************************
 *   INTERFACE / ENUMS
 **********************************************************************************************************/
import { generateCustomFieldInitialValues } from 'containers/shop/helpers/initialValues'
import { ICartItem } from 'models/shop/cart'
import { ICustomFieldGroup } from 'models/shop/product'
import { IDomainContact } from 'models/shop/shop.configure.d'

export type TDomainConfigurationFormValues = {
    item_uuid: string
    product_id: number
    domain_contact_fields: IDomainContact
    order_type: string
    use_account_contact: boolean
    billing_cycle_id: number | null
    id_protection: boolean
    auto_renew: boolean
    epp?: string
    custom_fields: ReturnType<typeof generateCustomFieldInitialValues>
}

type THandleSubmitArgs = [TDomainConfigurationFormValues, FormikHelpers<TDomainConfigurationFormValues>]
type THandleSubmit = (...args: THandleSubmitArgs) => Promise<void>
type TValidateBeforeSubmit = (handleSubmit: THandleSubmit) => (...args: THandleSubmitArgs) => Promise<void>

/**
 * Formik Validation Schema - Validation for custom fields is handled on a per field basis
 */
const schema = Yup.object({
    domain_contact_fields: Yup.object().shape({
        firstname: Yup.string().required('First name is required'),
        lastname: Yup.string().required('Last name is required'),
        email: Yup.string().email('Invalid Email address').required('Email Address is required'),
        address1: Yup.string().required('Address is required'),
        address2: Yup.string(),
        organisation: Yup.string(),
        suburb: Yup.string().required('Suburb is required'),
        state: Yup.string().required('State is required'),
        country: Yup.string().required('Country is required'),
        postcode: Yup.string().matches(regex.specialCharacters, 'Postcode cannot have special characters').required('Postcode is required'),
        phone: Yup.string().matches(regex.internationalPhone, 'Invalid phone number').required('Phone Number is required')
    }),
    epp: Yup.string().when('order_type', {
        is: 'transfer',
        then: (schema) => schema.required('EPP Code is required to transfer domain')
    })
})

/**
 * Provides a list of custom fields and fields that contain the word Eligibility. Since there should only ever be one
 * Eligibility field groups, this function returns the first group that contains the word Eligibility.
 */
const getEligibilityFields = (customFields: Readonly<ICustomFieldGroup[]> | undefined) => {
    const _eligibilityFields = customFields?.filter((group) => group.name.includes('Eligibility'))?.[0]
    const _customFields = customFields?.filter((group) => !group.name.includes('Eligibility'))

    return [_eligibilityFields, _customFields] as [ICustomFieldGroup | undefined, ICustomFieldGroup[] | undefined]
}

/**
 * Helper function to convert checkbox values from boolean to string during the submission stage
 */
const convertCheckboxValuesFromBoolToString = (
    custom_field_groups: ICustomFieldGroup[] | undefined,
    custom_fields: {
        id: number
        value: string | boolean
    }[]
) => {
    // Get Id's of fields that are checkboxes
    const checkboxIds = custom_field_groups?.reduce((acc, group) => {
        return [...acc, ...group.custom_field_inputs.filter(({ field_type }) => field_type === 'checkbox').map(({ id }) => id)]
    }, [] as number[])

    // replace fields that are checkboxes with a string representation
    return custom_fields.map((field) => {
        if (!checkboxIds?.includes(field.id)) return field

        return {
            ...field,
            value: field.value ? '1' : '0'
        }
    })
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function ConfigureDomains() {
    /***** HOOKS *****/
    const navigate = useNavigate()
    const params = useParams()
    const { cart, cartItem, default_country, current_account } = useConfigureDomainsSelector(params.id)
    const formikRef = useRef<FormikProps<TDomainConfigurationFormValues>>(null)
    const promo = useAppSelector((state) => state.shop.searchParamPromotion) ?? undefined

    /***** QUERIES *****/
    const {
        data: domainData,
        isLoading: isDomainDataLoading,
        isFetching: isDomainDataFetching
    } = useDomainAvailabilityWithProductQuery(
        { domain: cartItem?.name ?? '', productId: cartItem?.product?.id ?? -1, promo },
        {
            skip: !cartItem?.name || !cartItem?.product?.id
        }
    )

    /***** MUTATIONS *****/
    const [, { isLoading: isCreateCartLoading }] = useCreateCartMutation()
    const [checkDomainSuggestions, { isLoading: isCheckDomainSuggestionLoading }] = useCheckDomainSuggestionsMutation({
        fixedCacheKey: 'check-domain-suggestion'
    })
    const [updateCart, { isLoading: isUpdateCartLoading, isSuccess: isUpdateCartSuccess }] = useUpdateCartItemsMutation(
        /* TEMP-START */
        // TODO: temp hack, see note in "containers/shop/shop.tsx"
        {
            fixedCacheKey: 'update-cart-from-services-configure[bug]'
        }
        /* TEMP-END */
    )

    /***** EFFECTS *****/
    useEffect(() => {
        if (!domainData && cartItem && !isUpdateCartSuccess) {
            checkDomainSuggestions({ domain: cartItem.name, promo })
        }
    }, [domainData, cartItem, isUpdateCartSuccess, promo])

    useEffect(() => {
        if (!cart?.items) {
            navigate('/shop/purchase')
        }
    }, [cart])

    /*   RENDER COMPONENT
     *****************************************************/
    if (isDomainDataLoading || isCheckDomainSuggestionLoading || isDomainDataFetching) {
        return (
            <AnimatePresence>
                <Services.Loading variants={componentTransitionVariants} initial={'initial'} animate={'animate'} exit={'exit'}>
                    <Loader message={'Fetching Domain Configuration...'} width={48} height={48} />
                </Services.Loading>
            </AnimatePresence>
        )
    }

    if (!domainData || !cartItem) return null

    const [eligibilityField, customFields] = getEligibilityFields(domainData.product.custom_field_groups)

    function getInitialDomainContactCountry() {
        if (cartItem?.domain_contact_fields?.country) {
            return cartItem.domain_contact_fields?.country
        }

        return typeof default_country === 'string' ? default_country : 'AU'
    }

    const generateStaticInitialValues = (): Omit<TDomainConfigurationFormValues, 'custom_fields'> => ({
        item_uuid: cartItem.item_uuid ?? '',
        product_id: cartItem.product.id ?? null,
        domain_contact_fields: {
            firstname: cartItem.domain_contact_fields?.firstname ?? '',
            lastname: cartItem.domain_contact_fields?.lastname ?? '',
            email: cartItem.domain_contact_fields?.email ?? '',
            address1: cartItem.domain_contact_fields?.address1 ?? '',
            address2: cartItem.domain_contact_fields?.address2 ?? '',
            organisation: cartItem.domain_contact_fields?.organisation ?? '',
            suburb: cartItem.domain_contact_fields?.suburb ?? '',
            state: cartItem.domain_contact_fields?.state ?? 'VIC',
            country: getInitialDomainContactCountry(),
            postcode: cartItem.domain_contact_fields?.postcode ?? '',
            phone: cartItem.domain_contact_fields?.phone ?? ''
        },
        order_type: cartItem.order_type ?? '',
        use_account_contact: false,
        billing_cycle_id: cartItem.billing_cycle.id ?? null,
        id_protection: typeof cartItem.id_protection === 'boolean' ? cartItem.id_protection : true,
        auto_renew: cartItem.auto_renew ?? true,
        epp: cartItem?.epp ?? ''
    })

    const validateBeforeSubmit: TValidateBeforeSubmit =
        (handleSubmit) =>
        async (...args) => {
            await formikRef.current?.validateForm()
            if (!formikRef.current?.isValid) return

            await handleSubmit(...args)
        }

    const handleSubmit: THandleSubmit = async (values, { setTouched }) => {
        const custom_field_groups = domainData.product.custom_field_groups
        const custom_fields = values.custom_fields

        const item = {
            item_uuid: values.item_uuid,
            name: cartItem.name,
            order_type: values.order_type,
            product_id: values.product_id,
            billing_cycle_id: values.billing_cycle_id,
            auto_renew: values.auto_renew,
            domain_contact_fields: values.domain_contact_fields,
            custom_fields: convertCheckboxValuesFromBoolToString(custom_field_groups, custom_fields).filter((field) => {
                // If "Eligibility Type" is "Citizen/Resident", don't send the "Eligibility Number" field
                const eligibilityNumberFieldId = eligibilityField?.custom_field_inputs.find((field) => field.field_name === 'Eligibility Number')?.id

                if (field.id !== eligibilityNumberFieldId) {
                    return true
                }

                return !checkIsEligibilityTypeCitizenResident(values, eligibilityField)
            })
        } as Record<string, unknown>

        if (values.order_type === 'transfer') {
            item.epp = values.epp
        }

        if (domainData.meta.allow_id_protection) {
            item.id_protection = values.id_protection
        }

        const updateCartResult = await updateCart({
            uuid: cart?.uuid,
            items: [item]
        })
            .unwrap()
            .catch(() => {
                setTouched({})
                return { error: true }
            })

        if ('error' in updateCartResult && updateCartResult?.error) return

        navigate('/shop/purchase')
    }

    return (
        <Formik<TDomainConfigurationFormValues>
            enableReinitialize
            innerRef={formikRef}
            initialValues={{
                ...generateStaticInitialValues(),
                custom_fields: generateCustomFieldInitialValues({ cartItem, customFieldGroups: domainData.product.custom_field_groups })
            }}
            validationSchema={schema}
            onSubmit={validateBeforeSubmit(handleSubmit)}
        >
            {({ isSubmitting }) => {
                return (
                    <AnimatePresence>
                        <motion.div variants={componentTransitionVariants} initial={'initial'} animate={'animate'} exit={'exit'}>
                            <Configure.Title>{cartItem.name}</Configure.Title>
                            <Registration.Form>
                                <Registration.Body>
                                    {/* Static Domain related fields */}
                                    <ToggleFields domain={domainData} cartItem={cartItem} />

                                    {/* Eligibility Fields */}
                                    {!!eligibilityField && (
                                        <CustomFieldGroups group={eligibilityField}>
                                            <Registration.Note>
                                                <strong>{domainData.product.name}</strong> domain names require eligibility in order to be registered.
                                                Please enter your eligibility information below. <br />
                                                <br />
                                                If you do not have eligibility information, you may still complete your order but{' '}
                                                <strong>
                                                    you will be still required to provide your eligibility information for us to secure and register
                                                    your domain.
                                                </strong>
                                            </Registration.Note>
                                        </CustomFieldGroups>
                                    )}

                                    {/* Other Custom Fields */}
                                    {customFields?.map((group) => (
                                        <CustomFieldGroups group={group} key={group.id} />
                                    ))}

                                    {/* Static EPP Code Field for domain transfers */}
                                    {cartItem.order_type === 'transfer' && domainData.meta?.require_epp_code && (
                                        <EPPCodeField isSubmitting={isSubmitting} cartItem={cartItem} />
                                    )}

                                    {/* Personal Details Fields */}
                                    <PersonalFields />
                                </Registration.Body>
                                <Registration.Footer>
                                    <Button type={'button'} onClick={() => navigate('/shop/purchase')} disabled={isSubmitting}>
                                        Back
                                    </Button>
                                    <Button
                                        type={'submit'}
                                        color={'primary'}
                                        loading={(isCreateCartLoading || isUpdateCartLoading) && 'Updating'}
                                        disabled={isSubmitting || isCreateCartLoading || isUpdateCartLoading}
                                    >
                                        Update
                                    </Button>
                                </Registration.Footer>
                            </Registration.Form>
                            {current_account && <UpdateState />}
                        </motion.div>
                    </AnimatePresence>
                )
            }}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   HELPER COMPONENTS START
 **********************************************************************************************************/
function EPPCodeField({ isSubmitting, cartItem }: { isSubmitting: boolean; cartItem: ICartItem }) {
    return (
        <>
            <Registration.Title top={true}>Eligibility Details</Registration.Title>
            <Registration.Note>
                A special transfer code obtained through your current provider is required to transfer <strong>{cartItem.name}</strong> <br />
                Be sure to copy and paste the EPP code to avoid adding or missing any characters.
            </Registration.Note>
            <Registration.Section>
                <Registration.Row>
                    <Field label='EPP Code' name='epp' type='text' disabled={isSubmitting} component={NXUIForm.InputField} />
                </Registration.Row>
            </Registration.Section>
        </>
    )
}

function UpdateState() {
    const { values, initialValues, setFieldValue, isSubmitting } = useFormikContext<TDomainConfigurationFormValues>()
    const { default_country, user, current_account } = useConfigureDomainsSelector()

    useEffect(() => {
        if (isSubmitting) {
            return
        }

        if (values.use_account_contact) {
            setFieldValue('domain_contact_fields', {
                firstname: user?.firstname ?? '',
                lastname: user?.lastname ?? '',
                email: user?.email ?? '',
                address1: user?.address.address1 ?? '',
                address2: user?.address.address2 ?? '',
                organisation: current_account?.account.company_name,
                suburb: user?.address.city ?? '',
                state: user?.address.state ?? '',
                country: user?.address.country ?? default_country,
                postcode: user?.address.postcode ?? '',
                phone: user?.phone ?? ''
            })
        } else {
            setFieldValue('domain_contact_fields', initialValues.domain_contact_fields)
        }
    }, [values.use_account_contact])

    return null
}
/**********************************************************************************************************
 *   HELPER COMPONENTS END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Loader } from 'components/loader'
import { Shop as NXUIShop } from 'nxui/src'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   API
 **********************************************************************************************************/
import { useCreateCartMutation, useProductGroupListQuery, useUpdateCartItemsMutation } from 'api/shop'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
import { Product, Services } from 'containers/shop/shop.styles'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { componentTransitionVariants, defaultCurrency, getDefaultCurrencyCode, isProductPricingZero } from 'helpers/utils'

/**********************************************************************************************************
 *   TYPES/INTERFACE
 **********************************************************************************************************/
import { ICartCreateItem, ICartItem } from 'models/shop/cart.d'
import { IProduct } from 'models/shop/product.d'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function ServicesShopComponent() {
    const navigate = useNavigate()
    const { cart } = useAppSelector((state) => state.app)
    const [isLoading, setIsLoading] = useState<string | boolean>()
    const [redirect, setRedirect] = useState<number>(0)
    const { activeProductGroup } = useAppSelector((state) => state.shop)
    const { data: productListData, isFetching: isProductListFetching } = useProductGroupListQuery(activeProductGroup?.id, {
        skip: !activeProductGroup?.id ?? false
    })
    const [updateCartItems, { isLoading: isUpdateCartLoading, isSuccess: isUpdateCartSuccess }] = useUpdateCartItemsMutation({
        fixedCacheKey: 'update-cart-items'
    })
    const [createCart, { isLoading: isCreateCartLoading, isSuccess: isCreateCartSuccess }] = useCreateCartMutation()

    function selectProduct(product: IProduct) {
        const config_options =
            Array.isArray(product.config_option_groups) && product.config_option_groups.length >= 1
                ? product.config_option_groups?.flatMap((configOptionGroup) =>
                      configOptionGroup.config_options.map((configOption) => {
                          if (configOption.values.length <= 0) {
                              throw new Error(
                                  `Config option "${configOption.name}" in config group "${configOptionGroup.name}" does not have any options configured.`
                              )
                          }

                          return {
                              config_id: configOption.id,
                              option_id: configOption.values[0].id
                          }
                      })
                  )
                : []

        const {
            pricing: { 0: firstPricing }
        } = product

        const cartItem: ICartCreateItem = {
            pending: true,
            order_type: 'all',
            product_id: product.id,
            billing_cycle_id: firstPricing.billing_cycle.id,
            config_options
        }

        if (cart?.uuid) {
            updateCartItems({ uuid: cart?.uuid, items: [cartItem] })
        } else {
            createCart({ items: [cartItem] })
        }
        setRedirect(product.id)
    }

    useEffect(() => {
        if (isUpdateCartSuccess || isCreateCartSuccess) {
            if (!cart?.items) return

            const cartItem = cart?.items.find((item: ICartItem): boolean => item.product.id === redirect && item.pending)

            if (cartItem) {
                navigate(`/shop/configure/services/${cartItem.item_uuid}`)
            }
        }
    }, [isUpdateCartSuccess, isCreateCartSuccess])

    useEffect(() => {
        if (isUpdateCartLoading || isCreateCartLoading) {
            setIsLoading(isUpdateCartLoading ? 'Updating cart...' : 'Creating cart...')
        }
    }, [isUpdateCartLoading, isCreateCartLoading])

    function renderProductList() {
        if (isProductListFetching || isLoading) {
            return (
                <Services.Loading
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: 1,
                        transition: {
                            duration: 0.5,
                            ease: [0.83, 0, 0.17, 1]
                        }
                    }}
                    exit={{ opacity: 0 }}
                >
                    <Loader width={45} height={45} message={'Fetching Available Products...'} />
                </Services.Loading>
            )
        }

        if (!productListData) return null

        return productListData.flatMap((product: IProduct) => {
            if (product.status === 'disabled') return []

            /**
             * When there are config options for the product, a "from" prefix can be shown (as the price may be higher if the user selects a config option after adding this item to the cart)
             * If there are no config options then a "from" prefix does not need to be present which is handled by this function.
             */
            const renderPricePrefix = () => {
                if (!product.config_option_groups?.length) {
                    return ''
                }

                const hasNonZeroPrice = product.config_option_groups.some((group) => {
                    return group.config_options.some(({ values }) => {
                        return values.some(({ pricing }) => {
                            return !isProductPricingZero(pricing)
                        })
                    })
                })

                return hasNonZeroPrice ? 'From' : ''
            }

            return (
                <motion.div key={product.id} variants={componentTransitionVariants} initial={'initial'} animate={'animate'} exit={'exit'}>
                    <NXUIShop.ServiceProduct
                        availability={!(!product?.pricing || product.pricing.length === 0)}
                        title={product.name}
                        price={
                            isProductPricingZero(product.pricing)
                                ? undefined
                                : `${renderPricePrefix()} ${defaultCurrency(product.pricing[0]?.price)} ${getDefaultCurrencyCode()}`
                        }
                        description={product.description}
                        features={Array.isArray(product.features) && product.features.length >= 1 ? product.features : undefined}
                        disabled={isUpdateCartLoading || isCreateCartLoading}
                        select={() => selectProduct(product)}
                    />
                </motion.div>
            )
        })
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return <Product.Selection>{renderProductList()}</Product.Selection>
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { ServicesShopComponent as PurchaseServices }

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useProductGroupsQuery, useUpdateCartItemsMutation } from 'api/shop'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setActiveProductGroup, setPromotionFromSearchParams } from 'store/slices/shopSlice'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { ShopCheckoutTemplate } from './templates/checkout'
import { ShopLandingTemplate } from './templates/landing'
import { ShopOrderTemplate } from './templates/order'
import { ShopPurchaseTemplate } from './templates/purchase'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Shop } from 'containers/shop/shop.styles'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { usePromotionSearchParam } from './hooks/promotion/usePromotionSearchParams'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function ShopComponent() {
    /***** HOOKS *****/
    const {
        appSettings: { section: settingsSections }
    } = useAppSelector((state) => state.app)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { activeProductGroup } = useAppSelector((state) => state.shop)
    const [promoParam] = usePromotionSearchParam()

    /***** QUERIES *****/
    const { data: productGroupData } = useProductGroupsQuery()

    /* TEMP-START */
    // TODO: this is a hack that is needed because the navigation to "/shop/purchase" after form submission in "containers/shop/services/services.configure.tsx" for some reason does not cause this component to re-render and read the new location.
    // Remove this if that bug can be fixed
    const [, { isSuccess: isUpdateCartItemsSuccess, data: updateCartItemsData }] = useUpdateCartItemsMutation({
        fixedCacheKey: 'update-cart-from-services-configure[bug]'
    })
    /* TEMP-END */

    /***** EFFECTS *****/
    // If domains are disabled in settings, set active product group to the first non domain product group
    useEffect(() => {
        if (!settingsSections.shop.domains && settingsSections.shop.products && Array.isArray(productGroupData) && productGroupData.length > 0) {
            dispatch(setActiveProductGroup(productGroupData[0]))
        }
    }, [productGroupData])

    useEffect(() => {
        if (isUpdateCartItemsSuccess) {
            const pendingDomain = updateCartItemsData?.items?.find((item) => item.product.service_type === 'domain' && item.pending)

            if (pendingDomain) {
                navigate(`/shop/configure/domains/${pendingDomain.item_uuid}`)
            } else {
                navigate('/shop/purchase')
            }
        }
    }, [isUpdateCartItemsSuccess])

    useEffect(() => {
        if (promoParam) dispatch(setPromotionFromSearchParams(promoParam))
    }, [promoParam])

    /***** RENDER HELPERS *****/
    function renderBrowseTitle() {
        let title = ''
        let { description } = activeProductGroup

        if (location.pathname.includes('/shop/checkout')) {
            title = 'Checkout'
            description =
                'Here you can review your order and complete your purchase. Please make sure you double check your product configurations before submitting your order.'
        }

        if (location.pathname.includes('/shop/configure/domains')) {
            title = 'Configure your Domain'
            description = 'Please review and configure your domain name below.'
        }

        if (location.pathname.includes('/shop/configure/services')) {
            title = 'Configure Your Service'
            description = 'Please review and configure your service below.'
        }

        if (!title && !description) return null

        return (
            <Shop.Browse.Title>
                <h1>{title}</h1>
                <p>{typeof description === 'string' && description}</p>
            </Shop.Browse.Title>
        )
    }

    /***** RENDER *****/
    switch (true) {
        case location.pathname.includes('/shop/landing'):
            return <ShopLandingTemplate />
        case location.pathname.includes('/shop/order'):
            return <ShopOrderTemplate />
        case location.pathname.includes('/shop/checkout'):
            return <ShopCheckoutTemplate browserTitle={renderBrowseTitle()} />
        default:
            return <ShopPurchaseTemplate browserTitle={renderBrowseTitle()} />
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { ShopComponent as Shop }

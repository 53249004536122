/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { motion } from 'framer-motion'
import { Outlet } from 'react-router-dom'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { componentTransitionVariants } from 'helpers/utils'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { Shop } from '../shop.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const ShopOrderTemplate = () => (
    <Shop.Base as={motion.div} variants={componentTransitionVariants} initial='initial' animate='animate' exit='exit'>
        <Shop.Title>Shop</Shop.Title>
        <Outlet />
    </Shop.Base>
)
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

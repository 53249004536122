/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { QuestionMarkCircleIcon, StopIcon } from '@heroicons/react/24/outline'
import { FormikProps } from 'formik'
import { RefObject, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Loader } from 'components/loader'
import { Badge, Button, MultiSelect, Tooltip } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import DomainSearch from 'containers/shop/domain/domain.search'
import { IServiceConfigurationForm } from 'containers/shop/services/configure'
import { RenderWhileMultipleTLDsAvailable, useTLDList } from './helper'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useCheckDomainAvailabilityMutation, useCheckDomainSuggestionsMutation, useUpdateCartItemsMutation } from 'api/shop'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Result } from 'containers/shop/domain/domain.styles'
import { Services } from 'containers/shop/shop.styles'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { defaultCurrency, getDomainName } from 'helpers/utils'
import { useShopLocation } from 'router/helpers'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   INTERFACE / ENUMS
 **********************************************************************************************************/
import { IDomainAvailability } from 'models/shop/domain'
import { IPricing } from 'models/shop/shop'
import { OwnSelectDomain } from './components/selectDomain'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface Props {
    embedded: boolean
    serviceConfigRef?: RefObject<FormikProps<IServiceConfigurationForm>>
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function DomainRegister({ embedded = false, serviceConfigRef }: Props) {
    /***** STATE *****/
    const [selectedPricing, setSelectedPricing] = useState<Array<{ id: number; selectedPrice: IPricing }>>([])
    const [filter, setFilter] = useState<'all' | 'available' | 'premium'>('all')

    /***** HOOKS *****/
    const { searchTerm, searchParamPromotion } = useAppSelector((state) => state.shop)
    const { cart } = useAppSelector((state) => state.app)
    const { state } = useShopLocation()
    const navigate = useNavigate()
    const tldList = useTLDList()

    /***** QUERIES *****/
    const [updateCartItems] = useUpdateCartItemsMutation({ fixedCacheKey: 'update-cart-items' })
    const [, { data: availabilityData, isSuccess: isAvailabilitySuccess, isLoading: isAvailabilityLoading }] = useCheckDomainAvailabilityMutation({
        fixedCacheKey: 'check-domain-availability'
    })
    const [checkDomainSuggestions, { data: suggestionData, isLoading: isSuggestionLoading }] = useCheckDomainSuggestionsMutation({
        fixedCacheKey: 'check-domain-suggestion'
    })

    const domain = state?.search?.split('.')[0] ?? getDomainName(searchTerm)
    const pendingRegistrationItems = cart?.items?.filter((item) => item.pending && item.order_type === 'register')
    const options = () => {
        const items = [
            {
                type: 'button',
                label: 'All',
                active: filter === 'all',
                onClick: () => setFilter('all')
            }
        ]

        if (suggestionData?.some(({ availability }) => availability)) {
            items.push({
                type: 'button',
                label: 'Available Only',
                active: filter === 'available',
                onClick: () => setFilter('available')
            })
        }

        if (suggestionData?.some(({ premium }) => premium)) {
            items.push({
                type: 'button',
                label: 'Premium Domains',
                active: filter === 'premium',
                onClick: () => setFilter('premium')
            })
        }

        return items
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (isAvailabilitySuccess && searchTerm && Number(tldList?.length) > 1) {
            checkDomainSuggestions({ domain: searchTerm, promo: searchParamPromotion ?? undefined })
            setFilter('all')
        }
    }, [isAvailabilitySuccess, searchTerm, tldList])

    /***** RENDER HELPERS *****/
    function renderAvailabilityBanner() {
        if (!isAvailabilitySuccess || !availabilityData) return null

        if (availabilityData.availability) {
            return (
                <Services.Check color={'confirm'}>
                    Great news, {`${domain}${availabilityData.product.name}`} is available for registration!
                </Services.Check>
            )
        }

        return <Services.Check color={'error'}>{`${domain}${availabilityData.product.name}`} is unavailable for registration.</Services.Check>
    }

    function renderPricingDropdown({ id, name, pricing, premium }: { id: number; name: string; pricing: Array<IPricing>; premium: boolean }) {
        const registerPrices = pricing.filter((price) => price.type === 'register')

        const optionsList = registerPrices.map((item: IPricing) => {
            if (premium) {
                return {
                    key: `${defaultCurrency(item.cost)} / ${item.billing_cycle.name}`,
                    label: (
                        <>
                            {item.old_price && item.old_price !== '0.00' ? <del>{defaultCurrency(item.old_price)}</del> : ''}
                            {`${defaultCurrency(item.cost)} / ${item.billing_cycle.name}`}
                        </>
                    ),
                    onClick: () => setSelectedPricing([...selectedPricing, { id, selectedPrice: item }])
                }
            }

            const price = item.sale_price ?? item.price
            const oldPrice = item.sale_price ? item.price : item.old_price

            return {
                key: `${defaultCurrency(price)} / ${item.billing_cycle.name}`,
                label: (
                    <>
                        {oldPrice && oldPrice !== '0.00' ? <del>{defaultCurrency(oldPrice)}</del> : ''}
                        {`${defaultCurrency(price)} / ${item.billing_cycle.name}`}
                    </>
                ),
                onClick: async () => {
                    const cartItem = cart?.items?.find((cartItem) => cartItem.name === name)

                    if (cartItem) {
                        await updateCartItems({
                            uuid: cart?.uuid,
                            items: [
                                {
                                    item_uuid: cartItem.item_uuid,
                                    pending: true,
                                    order_type: 'register',
                                    product_id: cartItem.product.id,
                                    billing_cycle_id: item.billing_cycle_id,
                                    name: cartItem.name
                                }
                            ]
                        })
                    } else {
                        setSelectedPricing([...selectedPricing, { id, selectedPrice: item }])
                    }
                }
            }
        })

        return <Result.BillingCycle id={`${id}-pricing`} name={`${id}-pricing`} options={optionsList} />
    }

    const renderResultsRow = (item: IDomainAvailability) => {
        const {
            availability,
            product: { id, name: tld },
            pricing,
            premium
        } = item

        if (availability) {
            return (
                <Result.Base key={id}>
                    <Result.Row>
                        <Result.Domain>
                            <Result.Title>
                                {domain}
                                <Result.TLD>{tld}</Result.TLD>
                            </Result.Title>
                            <Result.Status>
                                <Badge color={'confirm'}>Available</Badge>
                            </Result.Status>
                            {premium && (
                                <Result.Status>
                                    <Badge color={'primary'}>Premium</Badge>
                                </Result.Status>
                            )}
                            <Result.TooltipWrapper>
                                <Tooltip
                                    as='span'
                                    content='If auto-renew is enabled, your domain will attempt renewal automatically 14 days before the end of the discount period. Please note the renewal price is subject to change'
                                >
                                    <QuestionMarkCircleIcon color='gray' width={20} />
                                </Tooltip>
                            </Result.TooltipWrapper>
                        </Result.Domain>
                    </Result.Row>
                    <Result.Row>
                        <Result.Price>{renderPricingDropdown({ id, name: `${domain}${tld}`, pricing, premium })}</Result.Price>
                        <Result.Action>
                            <OwnSelectDomain
                                embedded={embedded}
                                item={item}
                                cart={cart}
                                selectedPricing={selectedPricing}
                                serviceConfigRef={serviceConfigRef}
                            />
                        </Result.Action>
                    </Result.Row>
                </Result.Base>
            )
        }

        return (
            <Result.Base key={id}>
                <Result.Row>
                    <Result.Domain>
                        <Result.Title>
                            {domain}
                            <Result.TLD>{tld}</Result.TLD>
                        </Result.Title>
                    </Result.Domain>
                    <Badge color={'secondary'} icon={<StopIcon />}>
                        Taken
                    </Badge>
                </Result.Row>
            </Result.Base>
        )
    }

    const renderSuggestedResults = () => {
        if (isSuggestionLoading) {
            return (
                <Services.Loading>
                    <Loader width={45} height={45} message={'Fetching a few alternatives you might be interested in...'} />
                </Services.Loading>
            )
        }

        if (!suggestionData || suggestionData.length === 0) return null

        return (
            <Services.Suggested>
                {suggestionData
                    .filter((item) => {
                        if (filter === 'available') {
                            return item.availability
                        }

                        if (filter === 'premium') {
                            return item.premium
                        }

                        return true
                    })
                    .map((item) => renderResultsRow(item))}
            </Services.Suggested>
        )
    }

    function processDomainSelection() {
        if (cart?.items) {
            const [firstPendingItem] = cart.items.filter((pendingCartItem) => pendingCartItem.pending && pendingCartItem.order_type === 'register')
            navigate(`/shop/configure/domains/${firstPendingItem.item_uuid}`)
        }
    }

    function renderSearchResult() {
        if (isAvailabilityLoading) {
            return (
                <Services.Loading>
                    <Loader width={45} height={45} message={'Checking Domain Availability...'} />
                </Services.Loading>
            )
        }

        if (!availabilityData) return null

        return (
            <>
                {renderAvailabilityBanner()}
                <Services.Results>{renderResultsRow(availabilityData)}</Services.Results>
                <RenderWhileMultipleTLDsAvailable>
                    {suggestionData && !embedded && <Services.Subtitle>Alternatives you may be interested in</Services.Subtitle>}
                    {!embedded && renderSuggestedResults()}
                </RenderWhileMultipleTLDsAvailable>
            </>
        )
    }

    /***** RENDER *****/
    return (
        <>
            <div>
                <Services.Subtitle>Check Your Domain Availability</Services.Subtitle>
                <Services.Description>Register the domain name that represents you and your business.</Services.Description>
                <DomainSearch embedded={embedded} type='register' />

                <RenderWhileMultipleTLDsAvailable>
                    {!isSuggestionLoading && !isAvailabilityLoading && suggestionData && availabilityData && !embedded && (
                        <Services.Filter>
                            <MultiSelect variant='outline' options={options()} />
                        </Services.Filter>
                    )}
                </RenderWhileMultipleTLDsAvailable>
                {renderSearchResult()}
            </div>
            {!embedded && availabilityData && (
                <Services.Footer>
                    <Button
                        color='primary'
                        type='button'
                        disabled={!pendingRegistrationItems || pendingRegistrationItems.length === 0}
                        onClick={processDomainSelection}
                    >
                        Continue
                    </Button>
                </Services.Footer>
            )}
        </>
    )
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { DomainRegister as Register }

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useLocation, useNavigate } from 'react-router-dom'
import { websiteRoutes } from 'router/consts'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { TGenericUseLocation, TGenericUseNavigate } from './types'

/***** SHOP TYPE DEFINITIONS *****/
type TShopState = {
    search?: string
}
type TShopRoutes = string
type TUseShopNavigate = TGenericUseNavigate<TShopRoutes, TShopState>
type TUseShopLocation = TGenericUseLocation<TShopRoutes, TShopState>

/***** WEBSITE TYPE DEFINITIONS *****/
type TWebsiteState = unknown
type TWebsiteRoutes = ValueOf<typeof websiteRoutes>
type TUseWebsiteNavigate = TGenericUseNavigate<TWebsiteRoutes, TWebsiteState>
type TUseWebsiteLocation = TGenericUseLocation<TWebsiteRoutes, TWebsiteState>

/***** EXPORTS *****/
export const useShopNavigate: TUseShopNavigate = useNavigate
export const useShopLocation: TUseShopLocation = useLocation

// @ts-ignore
export const useWebsiteLocation: TUseWebsiteLocation = useLocation
export const useWebsiteNavigate: TUseWebsiteNavigate = useNavigate

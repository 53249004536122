/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useCheckoutCartMutation } from 'api/shop'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { useApplyAccountCreditToInvoice } from 'containers/billing/utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { readyAppTokenTypeOrderPayment, updateAppTokenTypeOrderPaymentStatus } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   INTERFACE & TYPES
 **********************************************************************************************************/
import { PaymentMethod } from 'models/billing'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
interface TokenTypeFormProps {
    selectedPaymentMethodId: PaymentMethod['id'] | null
}

export function TokenTypeForm({ selectedPaymentMethodId }: TokenTypeFormProps) {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { cart } = useAppSelector((state) => state.app)
    const { checkoutAccountCredit } = useAppSelector((state) => state.shop)

    const [checkoutCart] = useCheckoutCartMutation({ fixedCacheKey: 'checkout-cart' })
    const { applyAccountCreditToInvoice } = useApplyAccountCreditToInvoice()

    if (!cart || selectedPaymentMethodId === null) return <></>

    return (
        <Formik
            initialValues={{}}
            onSubmit={async () => {
                const order = await checkoutCart({
                    uuid: cart.uuid,
                    payment_method_id: selectedPaymentMethodId
                }).unwrap()

                const { isInvoiceStillUnpaid, isSuccess } = await applyAccountCreditToInvoice(order.invoice.id, checkoutAccountCredit.amount)

                if (isSuccess) {
                    // If the invoice is still unpaid, we will pop up the stipe modal as soon as the "order complete" page loads
                    if (isInvoiceStillUnpaid) {
                        dispatch(readyAppTokenTypeOrderPayment({ invoiceId: order.invoice.id, paymentMethodId: selectedPaymentMethodId }))
                    } else {
                        dispatch(updateAppTokenTypeOrderPaymentStatus('idle'))
                    }
                    navigate(`/shop/order/${order.id}`)
                }
            }}
        >
            <Form id='tokenTypeForm'></Form>
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   SHARED COMPONENTS
 **********************************************************************************************************/
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   HOOKS
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useRecoverPasswordMutation } from 'api/authentication'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
import { Auth } from 'containers/authentication/authentication.styles'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function AuthForgotForm() {
    /*   STATE
     *****************************************************/
    const [recipientEmail, setRecipientEmail] = useState('text@example.com')
    const [forgotTemplate, setForgotTemplate] = useState<'form' | 'success' | 'enquiry'>('form')
    const {
        appSettings: {
            provider: { email_support }
        }
    } = useAppSelector((state) => state.app)
    const [recoverPassword, { isLoading }] = useRecoverPasswordMutation()

    /*   RENDER COMPONENT
     *****************************************************/
    const PasswordRecoverForm = () => {
        return (
            <Formik
                initialValues={{
                    email: ''
                }}
                validationSchema={Yup.object({
                    email: Yup.string().email('Invalid email address').required('Required')
                })}
                onSubmit={async ({ email }, { setSubmitting }) => {
                    try {
                        setRecipientEmail(email)
                        await recoverPassword({ email }).unwrap()
                        setForgotTemplate('success')
                    } catch (error) {
                        setSubmitting(false)
                    }
                }}
            >
                {({ isSubmitting }) => {
                    return (
                        <Auth.Wrapper>
                            <Auth.Title>Reset Account Password</Auth.Title>
                            <Auth.Description>Fill out the form below to complete your password reset request.</Auth.Description>
                            <Auth.Break />
                            <Auth.Form>
                                <Field
                                    label={'Email address'}
                                    name={'email'}
                                    type={'email'}
                                    disabled={isSubmitting}
                                    component={NXUIForm.InputField}
                                />
                                <Auth.Submit color={'primary'} type={'submit'} disabled={isSubmitting} loading={isSubmitting ? 'Submitting' : false}>
                                    Request Password Reset
                                </Auth.Submit>
                            </Auth.Form>
                            <Auth.Break />
                            <Auth.Border />
                            <Auth.Footer>
                                <Auth.Link to='login'>Back to Login</Auth.Link>
                            </Auth.Footer>
                        </Auth.Wrapper>
                    )
                }}
            </Formik>
        )
    }

    const PasswordRecoverSuccess = () => {
        return (
            <Auth.Wrapper>
                <Auth.Title>Password Reset Link Sent</Auth.Title>
                <Auth.Description>
                    A password reset link has been sent to <strong>{recipientEmail}</strong>.
                </Auth.Description>
                <Auth.Break />
                <Auth.Row>
                    <Auth.Submit color={'primary'} onClick={() => setForgotTemplate('enquiry')}>
                        Didn&apos;t receive an email?
                    </Auth.Submit>
                </Auth.Row>
                <Auth.Break />
                <Auth.Border />
                <Auth.Footer>
                    <Auth.Link to='login'>Return to Login</Auth.Link>
                </Auth.Footer>
            </Auth.Wrapper>
        )
    }

    const Enquiry = () => {
        return (
            <Auth.Wrapper>
                <Auth.Title>Didn&apos;t receive an email?</Auth.Title>
                <Auth.Description>
                    Try checking your spam and junk folders. To ensure you receive emails to the correct inbox, please whitelist{' '}
                    <strong>{email_support}</strong>
                </Auth.Description>
                <Auth.Break />
                <Auth.Row>
                    <Auth.Submit
                        color={'primary'}
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={() => recoverPassword({ email: recipientEmail })}
                    >
                        Resend Password Reset Email
                    </Auth.Submit>
                </Auth.Row>
                <Auth.Break />
                <Auth.Border />
                <Auth.Footer>
                    <Auth.Link to='login'>Return to Login</Auth.Link>
                </Auth.Footer>
            </Auth.Wrapper>
        )
    }

    const Template = () => {
        switch (forgotTemplate) {
            case 'success':
                return PasswordRecoverSuccess()
            case 'enquiry':
                return Enquiry()
            default:
                return PasswordRecoverForm()
        }
    }

    return Template()
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { Field, Formik, FormikProps } from 'formik'
import { RefObject, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Loader } from 'components/loader'
import { Button, MultiSelect, Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   COMPONENT IMPORTS
 **********************************************************************************************************/
import { IServiceConfigurationForm } from 'containers/shop/services/configure'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { defaultCurrency } from 'helpers/utils'
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useProductQuery, useUpdateCartItemsMutation } from 'api/shop'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Registration } from 'containers/shop/domain/domain.styles'
import { Configure, Services } from 'containers/shop/shop.styles'

/**********************************************************************************************************
 *   INTERFACE / ENUMS
 **********************************************************************************************************/
import type { IAddon, IConfigOption, IProduct } from 'models/shop/product.d'

interface IAddonConfigurationForm {
    billing_cycle_id: number | null
    config_options: Array<{ config_id: number; option_id: number | string; quantity: number | Array<string> }>
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function ConfigureAddons({
    addon,
    parentServiceRef
}: {
    addon?: IAddon
    parentServiceRef?: RefObject<FormikProps<IServiceConfigurationForm>>
}) {
    const navigate = useNavigate()
    const params = useParams()

    /*   REF
     *****************************************************/
    const addonRef = useRef<FormikProps<IAddonConfigurationForm>>(null)

    /*   STATE
     *****************************************************/
    const { cart } = useAppSelector((state) => state.app)
    const service = cart?.items?.find((item) => item.item_uuid === params.id) ?? null
    const cartAddon = service?.addons?.find((item) => item.item_uuid === params.addonId)

    /*   MUTATIONS / QUERIES
     *****************************************************/
    const [updateCartItems, { isLoading: isUpdateCartItemsLoading }] = useUpdateCartItemsMutation()
    const { isLoading: isProductLoading, data } = useProductQuery(cartAddon?.product.id ?? skipToken)
    const product = addon ?? data

    useEffect(() => {
        if (!cart || !cart?.items) navigate('/shop/purchase')
    }, [cart])

    function handleCheckboxQuantity(quantity: number | Array<string>) {
        if (Array.isArray(quantity)) {
            if (quantity.length >= 1) return 1

            return null
        }

        return quantity
    }

    /*   RENDER COMPONENT
     *****************************************************/
    if (isProductLoading) {
        return (
            <Services.Loading>
                <Loader message={'Fetching Product Configuration...'} width={48} height={48} />
            </Services.Loading>
        )
    }

    if (!product) return null

    if (!cartAddon) {
        if (!parentServiceRef || !addon) return null

        return (
            <>
                <Registration.Dock>
                    <Registration.Body>
                        {renderBillingCycle(product, addonRef, parentServiceRef)}
                        {renderConfigOptions(product, addonRef, parentServiceRef)}
                    </Registration.Body>
                </Registration.Dock>
                <Registration.Footer>
                    <Button
                        type={'button'}
                        color={'primary'}
                        disabled={isUpdateCartItemsLoading}
                        loading={isUpdateCartItemsLoading && 'Adding to cart'}
                        onClick={() => {
                            const pendingAddon = parentServiceRef.current?.values.addons.find(({ product_id }) => product_id === addon.id)

                            updateCartItems({
                                uuid: cart?.uuid,
                                items: [
                                    {
                                        item_uuid: service?.item_uuid,
                                        name: service?.name,
                                        product_id: service?.product.id,
                                        order_type: 'all',
                                        billing_cycle_id: service?.billing_cycle.id,
                                        config_options: service?.config_options?.map((configOption) => ({
                                            config_id: configOption.config.id,
                                            option_id: configOption.option.id,
                                            quantity: configOption.quantity
                                        })),
                                        addons: [
                                            {
                                                product_id: pendingAddon?.product_id,
                                                billing_cycle_id: pendingAddon?.billing_cycle_id,
                                                config_options: pendingAddon?.config_options?.map((configOption) => ({
                                                    config_id: configOption.config_id,
                                                    option_id: Number(configOption.option_id),
                                                    ...(handleCheckboxQuantity(configOption.quantity) && {
                                                        quantity: handleCheckboxQuantity(configOption.quantity)
                                                    })
                                                }))
                                            }
                                        ],
                                        pending: service?.pending
                                    }
                                ]
                            })
                        }}
                    >
                        Add to Cart
                    </Button>
                </Registration.Footer>
            </>
        )
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Formik
            enableReinitialize
            validateOnMount
            innerRef={addonRef}
            initialValues={{
                billing_cycle_id: cartAddon.billing_cycle.id,
                config_options: !cartAddon.config_options
                    ? []
                    : cartAddon.config_options.map((configOption) => ({
                          config_id: configOption.config.id,
                          option_id: configOption.config.input_type === 'radio' ? String(configOption.option.id) : configOption.option.id,
                          ...(configOption.config.input_type === 'checkbox' && configOption.quantity ? { quantity: ['1'] } : { quantity: 1 }),
                          ...(configOption.config.input_type === 'number' && { quantity: configOption.quantity })
                      }))
            }}
            onSubmit={async ({ billing_cycle_id, config_options }) => {
                await updateCartItems({
                    uuid: localStorage.getItem('cart_uuid'),
                    items: [
                        {
                            billing_cycle_id,
                            item_uuid: params.addonId,
                            order_type: 'all',
                            product_id: cartAddon.product.id,
                            config_options: config_options?.map((configOption) => ({
                                config_id: configOption.config_id,
                                option_id: Number(configOption.option_id),
                                ...(handleCheckboxQuantity(configOption.quantity) && {
                                    quantity: handleCheckboxQuantity(configOption.quantity)
                                })
                            }))
                        }
                    ]
                })
                navigate('/shop/purchase')
            }}
        >
            {({ isValid, isSubmitting }) => (
                <>
                    <Configure.Title>{product?.name}</Configure.Title>
                    <Registration.Form>
                        <Registration.Body>
                            {renderBillingCycle(product, addonRef)}
                            {renderConfigOptions(product, addonRef)}
                        </Registration.Body>
                        <Registration.Footer>
                            <Button type={'button'} onClick={() => navigate('/shop/purchase')} disabled={isSubmitting}>
                                Back
                            </Button>
                            <Button type={'submit'} color={'primary'} disabled={!isValid || isSubmitting}>
                                Update Configuration
                            </Button>
                        </Registration.Footer>
                    </Registration.Form>
                </>
            )}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const renderBillingCycle = (
    product: IProduct,
    ref: RefObject<FormikProps<IAddonConfigurationForm>>,
    parentServiceRef?: RefObject<FormikProps<IServiceConfigurationForm>>
) => {
    if (parentServiceRef?.current) {
        const { values, setFieldValue } = parentServiceRef.current
        const serviceAddon = values.addons.find(({ product_id }) => product_id === product.id)
        const serviceAddonIndex = values.addons.findIndex(({ product_id }) => product_id === product.id)
        const billingCycle = product.pricing.find(({ billing_cycle: { id } }) => id === serviceAddon?.billing_cycle_id)

        return (
            <>
                <Registration.Title>
                    <Registration.Text>
                        <span>Billing Cycle</span>
                        <Registration.Highlight>{billingCycle && `+${defaultCurrency(billingCycle.price)}`}</Registration.Highlight>
                    </Registration.Text>
                </Registration.Title>
                <Services.Content>
                    <MultiSelect
                        options={product.pricing.map(({ billing_cycle }) => ({
                            type: 'button',
                            label: billing_cycle.name,
                            active: serviceAddon?.billing_cycle_id === billing_cycle.id,
                            onClick: () => setFieldValue(`addons.${serviceAddonIndex}.billing_cycle_id`, billing_cycle.id)
                        }))}
                    />
                </Services.Content>
            </>
        )
    }

    const billingCycle = product.pricing.find(({ billing_cycle: { id } }) => id === ref.current?.values.billing_cycle_id)

    return (
        <>
            <Registration.Title>
                <Registration.Text>
                    <span>Billing Cycle</span>
                    <Registration.Highlight>{billingCycle && `+${defaultCurrency(billingCycle.price)}`}</Registration.Highlight>
                </Registration.Text>
            </Registration.Title>
            <Services.Content>
                <MultiSelect
                    options={product.pricing.map(({ billing_cycle }) => ({
                        type: 'button',
                        label: billing_cycle.name,
                        active: ref.current?.values.billing_cycle_id === billing_cycle.id,
                        onClick: () => ref.current?.setFieldValue('billing_cycle_id', billing_cycle.id)
                    }))}
                />
            </Services.Content>
        </>
    )
}

const renderInputType = (
    configOption: IConfigOption,
    product: IProduct,
    addonRef: RefObject<FormikProps<IAddonConfigurationForm>>,
    parentServiceRef?: RefObject<FormikProps<IServiceConfigurationForm>>
) => {
    const serviceAddonIndex = parentServiceRef?.current?.values.addons.findIndex((addon) => addon.product_id === product.id)
    const index =
        (serviceAddonIndex || serviceAddonIndex === 0) &&
        parentServiceRef?.current?.values.addons[serviceAddonIndex]?.config_options?.findIndex(({ config_id }) => config_id === configOption.id)
    const configOptionIndex = addonRef.current?.values.config_options.findIndex(({ config_id }) => config_id === configOption.id)
    const optionName = parentServiceRef
        ? `addons.${serviceAddonIndex}.config_options.${index}.option_id`
        : `config_options.${configOptionIndex}.option_id`
    const quantityName = parentServiceRef
        ? `addons.${serviceAddonIndex}.config_options.${index}.quantity`
        : `config_options.${configOptionIndex}.quantity`
    const sortByDisplayOrder = [...configOption.values].sort((a, b) => a.display_order - b.display_order)
    const option = sortByDisplayOrder.find(({ id }) => {
        if (parentServiceRef && (serviceAddonIndex || serviceAddonIndex === 0) && (index || index === 0)) {
            const configOptions = parentServiceRef.current?.values.addons[serviceAddonIndex].config_options || []

            return id === Number(configOptions[index]?.option_id)
        }

        if (!parentServiceRef && (configOptionIndex || configOptionIndex === 0)) {
            return id === Number(addonRef.current?.values.config_options[configOptionIndex].option_id)
        }

        return false
    })
    const billingCycle = option?.pricing.find(({ billing_cycle: { id } }) => {
        if (parentServiceRef) return id === parentServiceRef.current?.values.billing_cycle_id

        return id === addonRef.current?.values.billing_cycle_id
    })

    if (!index && index !== 0 && !configOptionIndex && configOptionIndex !== 0) return null

    switch (configOption.input_type) {
        case 'dropdown':
            return (
                <div key={configOption.id}>
                    <Services.ConfigOption.Label htmlFor={optionName}>
                        {configOption.name}
                        <span>{billingCycle && Number(billingCycle.price) > 0 && `+${defaultCurrency(billingCycle.price)}`}</span>
                    </Services.ConfigOption.Label>
                    <Field
                        id={optionName}
                        name={optionName}
                        type='select'
                        list={sortByDisplayOrder.map((configOptionValue) => ({
                            label: configOptionValue.name,
                            value: configOptionValue.id
                        }))}
                        component={NXUIForm.SelectField}
                    />
                </div>
            )
        case 'checkbox':
            return (
                <div key={configOption.id}>
                    <Services.ConfigOption.Label htmlFor={quantityName}>
                        {configOption.name}
                        <span>{billingCycle && Number(billingCycle.price) > 0 && `+${defaultCurrency(billingCycle.price)}`}</span>
                    </Services.ConfigOption.Label>
                    {sortByDisplayOrder.map((configOptionValue) => (
                        <Field
                            id={quantityName}
                            key={String(configOptionValue)}
                            name={quantityName}
                            value={String(1)}
                            type={'checkbox'}
                            description={configOptionValue.name}
                            component={NXUIForm.CheckboxField}
                        />
                    ))}
                </div>
            )
        case 'radio':
            return (
                <div key={configOption.id} role={'group'} aria-labelledby={configOption.name}>
                    <Services.ConfigOption.Label htmlFor={optionName}>
                        {configOption.name}
                        <span>{billingCycle && Number(billingCycle.price) > 0 && `+${defaultCurrency(billingCycle.price)}`}</span>
                    </Services.ConfigOption.Label>
                    {sortByDisplayOrder.map((configOptionValue) => (
                        <Field
                            id={optionName}
                            key={String(configOptionValue.id)}
                            label={configOptionValue.name}
                            name={optionName}
                            value={String(configOptionValue.id)}
                            type={'radio'}
                            description={configOptionValue.name}
                            component={NXUIForm.RadioField}
                        />
                    ))}
                </div>
            )
        case 'number':
            return (
                <div key={configOption.id}>
                    {sortByDisplayOrder.map((configOptionValue) => (
                        <div key={configOptionValue.id}>
                            <Services.ConfigOption.Label htmlFor={quantityName}>
                                {`${configOption.name} (${configOptionValue.name})`}
                                <span>{billingCycle && Number(billingCycle.price) > 0 && `+${defaultCurrency(billingCycle.price)}`}</span>
                            </Services.ConfigOption.Label>
                            <Field id={quantityName} name={quantityName} type='number' min='1' step='1' component={NXUIForm.NumberField} />
                        </div>
                    ))}
                </div>
            )
        default:
            return null
    }
}

const renderConfigOptions = (
    product: IProduct,
    addonRef: RefObject<FormikProps<IAddonConfigurationForm>>,
    parentServiceRef?: RefObject<FormikProps<IServiceConfigurationForm>>
) => {
    if (!product.config_option_groups || (Array.isArray(product.config_option_groups) && product.config_option_groups.length === 0)) {
        return null
    }

    return (
        <>
            <Registration.Title>Configuration Options</Registration.Title>
            <Services.Content>
                {product.config_option_groups.map((configOptionGroup) =>
                    configOptionGroup.config_options.map((configOption) => renderInputType(configOption, product, addonRef, parentServiceRef))
                )}
            </Services.Content>
        </>
    )
}

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, useFormikContext } from 'formik'
import htmr from 'htmr'
import React from 'react'
import styled from 'styled-components'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import { Form as NXUIForm, Tooltip } from 'nxui/src'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import { Registration } from '../../domain.styles'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { generateCustomFieldInitialValues } from 'containers/shop/helpers/initialValues'
import { snakeCase } from 'helpers/utils'
import { checkIsEligibilityTypeCitizenResident } from '../helpers'

/**********************************************************************************************************
 *   INTERFACE / ENUMS
 **********************************************************************************************************/
import { ICustomFieldGroup } from 'models/shop/product'
import { TDomainConfigurationFormValues } from '../domain.configure'

type TCustomField = React.FC<{
    field: Readonly<ICustomFieldGroup['custom_field_inputs'][number]>
    group: Readonly<ICustomFieldGroup>
}>

type FormikInitialValues = {
    custom_fields: ReturnType<typeof generateCustomFieldInitialValues>
}

const useCustomFieldIndex = (id: number) => {
    const { values } = useFormikContext<FormikInitialValues>()

    return values.custom_fields.findIndex((item) => item.id === id)
}

const getCustomFieldName = (index: number) => `custom_fields.${index}.value`

const Styles = {
    TooltipWrapper: styled.div<{ bottom: number }>`
        margin-bottom: ${({ bottom }) => bottom}px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        z-index: 1;
        position: relative;
        height: 20px;
    `,
    TooltipIcon: styled(QuestionMarkCircleIcon)`
        width: 20px;
        height: 20px;
    `,
    // If NXUI is moved into MCA, we can adjust this to target the internal styling of the component directly
    Field: styled(Field)`
        & > label + div > div:hover {
            z-index: 1;
        }
    `
}
const S = Styles

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Generates a custom field who's name is in the standard `"custom_fields.{index}.value"` format
 *
 * Initial values in the form should therefore be in the format:
 * ```
 * {
 *   custom_fields: [{
 *     id: string,
 *     value: string
 *   }]
 * }
 * ```
 *
 * This can be generated using {@link generateCustomFieldInitialValues}
 */
export const CustomField: TCustomField = ({ field, group }) => {
    /***** HOOKS *****/
    const { isSubmitting, values } = useFormikContext<TDomainConfigurationFormValues>()
    const index = useCustomFieldIndex(field.id)
    const name = getCustomFieldName(index)
    const { required, field_name: label } = field

    /***** RENDER HELPERS *****/
    const validate = (value: unknown) => {
        if (field.required && !value) {
            return 'Required'
        }
    }

    const baseProps = {
        name,
        validate,
        required,
        label,
        id: snakeCase(label),
        disabled: isSubmitting
    }

    const isEligibilityTypeCitizenResident = checkIsEligibilityTypeCitizenResident(values, group)
    const isFieldHidden = field.field_name === 'Eligibility Number' && isEligibilityTypeCitizenResident

    /***** RENDER *****/
    if (isFieldHidden) {
        return null
    }

    switch (field.field_type) {
        case 'text':
            return (
                <Registration.Row>
                    <TooltipField content={field.description} placement='left-center'>
                        <S.Field {...baseProps} type={'text'} component={NXUIForm.InputField} />
                    </TooltipField>
                </Registration.Row>
            )

        case 'textarea':
            return (
                <Registration.Row>
                    <TooltipField content={field.description} placement='left-center'>
                        <Field {...baseProps} type={'text'} component={NXUIForm.TextAreaField} />
                    </TooltipField>
                </Registration.Row>
            )

        case 'password':
            return (
                <Registration.Row>
                    <TooltipField content={field.description} placement='left-center'>
                        <Field {...baseProps} type={'password'} autoComplete={'current-password'} component={NXUIForm.InputField} />
                    </TooltipField>
                </Registration.Row>
            )

        case 'checkbox':
            return (
                <Registration.Row>
                    <TooltipField bottom={5} content={field.description} placement='left-center'>
                        <Field
                            id={snakeCase(field.field_name)}
                            description={field.field_name}
                            name={name}
                            type={'checkbox'}
                            component={NXUIForm.CheckboxField}
                        />
                    </TooltipField>
                </Registration.Row>
            )

        case 'dropdown':
            return (
                <Registration.Row>
                    <TooltipField content={field.description} placement='left-center'>
                        <Field
                            {...baseProps}
                            type={'select'}
                            list={field.field_options?.split(',').map((option) => ({
                                label: option,
                                value: option
                            }))}
                            component={NXUIForm.SelectField}
                        />
                    </TooltipField>
                </Registration.Row>
            )

        default:
            return null
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

type TTooltipField = React.FC<{
    children: React.ReactNode
    content: string | null
    placement: string
    bottom?: number
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @private component to wrap the field with a tooltip.
 *
 * Note: Currently tooltips through NXUI do not support hovering the popup so this will need to be changed
 * If that functionality is required ie. hyperlinks in the tooltip.
 */
const TooltipField: TTooltipField = ({ children, content, placement, bottom = -18 }) => {
    /***** RENDER *****/
    if (!content) return <>{children}</>

    const _content = typeof content === 'string' ? htmr(content) : content

    return (
        <div>
            <S.TooltipWrapper bottom={bottom}>
                <Tooltip content={_content} placement={placement}>
                    <S.TooltipIcon />
                </Tooltip>
            </S.TooltipWrapper>
            {children}
        </div>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

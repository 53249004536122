/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { shopAPI, useRemovePromoCodeFromCartMutation } from 'api/shop'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Badge } from 'nxui/src'

/**********************************************************************************************************
 *   HELPERS
 **********************************************************************************************************/
import { useAppDispatch, useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function PromotionCodes() {
    const dispatch = useAppDispatch()
    const { cart } = useAppSelector((state) => state.app)
    const [removePromoCodeFromCart] = useRemovePromoCodeFromCartMutation()

    /*   RENDER COMPONENT
     *****************************************************/
    if (!cart || !cart?.promo_code) return null

    return (
        <Badge
            color={'alternative'}
            onClick={async () => {
                if (cart.promo_code) {
                    await removePromoCodeFromCart({
                        uuid: cart.uuid,
                        code: cart.promo_code
                    })
                    dispatch(shopAPI.endpoints.viewCart.initiate(cart.uuid, { forceRefetch: true }))
                }
            }}
        >
            <span>{cart?.promo_code.toUpperCase()}</span>
        </Badge>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

/**********************************************************************************************************
 *   API
 **********************************************************************************************************/
import { baseApi } from 'api/base'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { ICart, ICartCreateItem, ICartRemoveItem, ICheckout, ICheckoutPayload } from 'models/shop/cart.d'
import { IDomainAvailability, TDomainAvailableRequest, TDomainSuggestionRequest } from 'models/shop/domain.d'
import { IProduct, IProductGroup } from 'models/shop/product.d'
import { IDomainProducts } from '../../models/shop/shop'

/**********************************************************************************************************
 *   SHOP API
 **********************************************************************************************************/
export const shopAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        domainProductList: builder.query<Array<IProduct>, void>({
            query: () => 'client/ordering/domain',
            transformResponse: (response: { data: Array<IProduct> }) => response.data
        }),
        // Domain availability "mutation" is used for domain search used because we needed to trigger it only on submit of the domain search.
        checkDomainAvailability: builder.mutation<IDomainAvailability, TDomainAvailableRequest>({
            query: (body) => ({
                url: 'client/ordering/domain/availability',
                method: 'POST',
                body
            }),
            transformResponse: (baseQueryReturnValue: { data: IDomainAvailability }) => baseQueryReturnValue.data
        }),
        // Domain availability "query" is used to get domain product / eligibility information for editing the cart
        domainAvailability: builder.query<IDomainAvailability, TDomainAvailableRequest>({
            query: (body) => ({
                url: 'client/ordering/domain/availability',
                method: 'POST',
                body
            }),
            transformResponse: (baseQueryReturnValue: { data: IDomainAvailability }) => baseQueryReturnValue.data
        }),
        checkDomainSuggestions: builder.mutation<Array<IDomainAvailability>, TDomainSuggestionRequest>({
            query: (body) => ({
                url: 'client/ordering/domain/suggestion',
                method: 'POST',
                body
            }),
            transformResponse: (baseQueryReturnValue: { data: Array<IDomainAvailability> }) => baseQueryReturnValue.data
        }),
        productGroups: builder.query<Array<IProductGroup>, void>({
            query: () => 'client/ordering/product-groups',
            transformResponse: (baseQueryReturnValue: { data: Array<IProductGroup> }) => baseQueryReturnValue.data,
            providesTags: ['Product-Group']
        }),
        productGroupList: builder.query<Array<IProduct>, number>({
            query: (id) => `client/ordering/product-groups/${id}/products`,
            transformResponse: (baseQueryReturnValue: { data: Array<IProduct> }) => baseQueryReturnValue.data,
            providesTags: ['Product-List']
        }),
        productList: builder.query<Array<IProduct>, number>({
            query: (id) => `client/ordering/product-groups/${id}/products`,
            transformResponse: (baseQueryReturnValue: { data: Array<IProduct> }) => baseQueryReturnValue.data,
            providesTags: ['Product-List']
        }),
        product: builder.query<IProduct, number>({
            query: (id) => `client/ordering/products/${id}`,
            transformResponse: (baseQueryReturnValue: { data: IProduct }) => baseQueryReturnValue.data,
            providesTags: ['Product']
        }),
        createCart: builder.mutation<ICart, { items: Array<ICartCreateItem> }>({
            query: (items) => ({
                url: 'client/ordering/cart',
                method: 'POST',
                body: items
            }),
            transformResponse: (baseQueryReturnValue: { data: ICart }) => baseQueryReturnValue.data
        }),
        createEmptyCart: builder.mutation<ICart, void>({
            query: () => ({
                url: 'client/ordering/cart',
                method: 'POST',
                body: {
                    items: []
                }
            }),
            transformResponse: (baseQueryReturnValue: { data: ICart }) => baseQueryReturnValue.data
        }),
        viewCart: builder.query<ICart, string>({
            query: (uuid) => `client/ordering/cart/${uuid}`,
            transformResponse: (baseQueryReturnValue: { data: ICart }) => baseQueryReturnValue.data,
            providesTags: ['Cart']
        }),
        updateCartItems: builder.mutation({
            query: ({ uuid, ...rest }) => ({
                url: `client/ordering/cart/${uuid}`,
                method: 'PUT',
                body: rest
            }),
            transformResponse: (baseQueryReturnValue: { data: ICart }) => baseQueryReturnValue.data
        }),
        removeCartItem: builder.mutation<ICart, ICartRemoveItem>({
            query: ({ uuid, ...rest }) => ({
                url: `client/ordering/cart/${uuid}`,
                method: 'PUT',
                body: rest
            }),
            transformResponse: (baseQueryReturnValue: { data: ICart }) => baseQueryReturnValue.data,
            invalidatesTags: ['Cart']
        }),
        deleteCart: builder.mutation<void, string>({
            query: (uuid) => ({
                url: `client/ordering/cart/${uuid}`,
                method: 'DELETE'
            })
        }),
        addPromoCodeToCart: builder.mutation<void, { uuid: string; code: string }>({
            query: ({ uuid, ...rest }) => ({
                url: `client/ordering/cart/${uuid}/promo/add`,
                method: 'POST',
                body: rest
            }),
            invalidatesTags: ['Cart']
        }),
        removePromoCodeFromCart: builder.mutation<void, { uuid: string; code: string }>({
            query: ({ uuid, code }) => ({
                url: `client/ordering/cart/${uuid}/promo/remove`,
                method: 'POST',
                body: { code }
            }),
            invalidatesTags: ['Cart']
        }),
        checkoutCart: builder.mutation<ICheckout, ICheckoutPayload>({
            query: ({ uuid, ...rest }) => ({
                url: `client/ordering/cart/${uuid}/checkout`,
                method: 'POST',
                body: rest
            }),
            transformResponse: (baseQueryReturnValue: { data: ICheckout }) => baseQueryReturnValue.data
        }),
        domainAvailabilityWithProduct: builder.query<
            Omit<IDomainAvailability, 'product'> & { product: IProduct },
            { domain: string; productId: number; promo?: string }
        >({
            queryFn: async ({ domain, productId, promo }, _, __, baseQuery) => {
                type TAvailabilityQueryReturnValue = QueryReturnValue<
                    {
                        data: IDomainAvailability
                        meta: FetchBaseQueryMeta
                    },
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >

                type TProductQueryReturnValue = QueryReturnValue<
                    {
                        data: IProduct
                        meta: FetchBaseQueryMeta
                    },
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >

                const getDomainAvailability = async () =>
                    baseQuery({
                        url: 'client/ordering/domain/availability',
                        method: 'POST',
                        body: { domain, promo }
                    }) as TAvailabilityQueryReturnValue

                const getProductData = async () => baseQuery(`client/ordering/products/${productId}`) as TProductQueryReturnValue

                const domainAvailability = await getDomainAvailability()
                const productData = await getProductData()

                if (domainAvailability?.error || productData?.error) {
                    return {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we know one of them is not null
                        error: (domainAvailability?.error ?? productData?.error)!
                    } satisfies QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
                }

                return {
                    data: {
                        ...domainAvailability.data.data,
                        product: productData.data.data
                    },
                    meta: domainAvailability?.meta ?? productData?.meta
                } satisfies QueryReturnValue<Omit<IDomainAvailability, 'product'> & { product: IProduct }, FetchBaseQueryError, FetchBaseQueryMeta>
            }
        }),

        /**
         * Whois Lookup mutation.
         */
        whois: builder.mutation<string, { domain: string; token: string }>({
            query: ({ domain, token }) => ({
                url: `client/whois/${domain}`,
                method: 'POST',
                body: { 'g-recaptcha-response': token }
            }),
            transformResponse: (baseQueryReturnValue: { data: { raw: string } }) => baseQueryReturnValue.data.raw
        }),

        /**********************************************************************************************************
         *   TEMPORARY WEBSITE ENDPOINTS
         **********************************************************************************************************/
        domainProducts: builder.query<Array<IDomainProducts>, void>({
            query: () => ({
                url: 'client/ordering/product-groups/domains/products',
                method: 'GET'
            }),
            transformResponse: (baseQueryReturnValue: { data: Array<IDomainProducts> }) => baseQueryReturnValue.data
        })
    })
})

export const {
    usePrefetch,
    useDomainProductListQuery,
    useCheckDomainAvailabilityMutation,
    useDomainAvailabilityQuery,
    useCheckDomainSuggestionsMutation,
    useProductGroupsQuery,
    useProductGroupListQuery,
    useProductListQuery,
    useProductQuery,
    useCreateCartMutation,
    useCreateEmptyCartMutation,
    useViewCartQuery,
    useRemoveCartItemMutation,
    useUpdateCartItemsMutation,
    useDeleteCartMutation,
    useAddPromoCodeToCartMutation,
    useRemovePromoCodeFromCartMutation,
    useCheckoutCartMutation,
    useDomainAvailabilityWithProductQuery
} = shopAPI

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { motion } from 'framer-motion'
import { ReactElement } from 'react'

/**********************************************************************************************************
 *   ASSET IMPORTS
 **********************************************************************************************************/
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Theme, Tooltip } from 'nxui/src'

/**********************************************************************************************************
 *   INTERFACE
 **********************************************************************************************************/
import { IFieldDescription } from 'containers/shop/components/form.d'
import { Weight } from 'models/enums'

/**********************************************************************************************************
 *   STYLES
 **********************************************************************************************************/
import styled, { css } from 'styled-components'

const DescriptionText = css`
    position: relative;
    width: fit-content;
    padding: 10px;
    margin-top: 5px;
    font-size: 1.2rem;
    color: ${(props) => props.theme[`text--200`]};
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme[`border--100`]};
    border-left: 4px solid ${(props) => props.theme[`warning--100`]};
    background-color: ${(props) => props.theme[`background--000`]};
    ${Theme.Functions.setFont(`'Hind', sans-serif`, Weight.Medium)};
`

export const Field = {
    Description: {
        Icon: styled(QuestionMarkCircleIcon)`
            display: block;
            position: relative;
            width: 20px;
            height: 100%;
            color: ${(props) => props.theme[`warning--100`]};
        `,
        Text: styled.div<IFieldDescription>`
            ${DescriptionText}
        `,
        Wrapper: styled.div`
            cursor: pointer;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            width: 36px;
            padding: 0 4px;
            margin: 0;
            visibility: visible;
            opacity: 1;
            background-color: ${(props) => Theme.Functions.hexToRGBA(props.theme[`warning--100`], 0.175)};
            ${Theme.Functions.animation(`all`)};

            /* 
             * breakpoints--md is invalid, fix this the next time this component is being worked on
             * @link {src/declarations/styled.d.ts}
             */
            ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints--md`])} {
                padding: 0 10px;
            }
        `,
        Tip: styled(motion.div)`
            ${DescriptionText}
        `
    }
}

/**********************************************************************************************************
 *   RENDER DESCRIPTION TIP
 **********************************************************************************************************/
export const FieldDescriptionTip = (props: { content?: string }): ReactElement => {
    const { content } = props

    return (
        <>
            <Field.Description.Wrapper {...props}>
                <Tooltip type={'warning'} content={content}>
                    <Field.Description.Icon id={`description-icon`} />
                </Tooltip>
            </Field.Description.Wrapper>
        </>
    )
}

/**********************************************************************************************************
 *   RENDER DESCRIPTION TEXT
 **********************************************************************************************************/
export const FieldDescriptionText = (props: IFieldDescription): ReactElement => {
    // @ts-ignore
    return <Field.Description.Text {...props}>{props.children}</Field.Description.Text>
}

/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { useNavigate } from 'react-router-dom'

/**********************************************************************************************************
 *   COMPONENT IMPORTS
 **********************************************************************************************************/
import CartItemComponent from 'containers/shop/checkout/cart.items'
import { PaymentMethods } from 'containers/shop/checkout/paymentMethods'

/**********************************************************************************************************
 *   STYLE IMPORTS
 **********************************************************************************************************/
import { Payment } from 'components/invoice/invoice.styles'
import { CartItem, Checkout, Summary } from 'containers/shop/shop.styles'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function ShopCheckout() {
    const navigate = useNavigate()
    const { cart } = useAppSelector((state) => state.app)

    const renderCartItemList = () => {
        if (!cart?.items) {
            return (
                <Summary.Cart.Empty.Base>
                    <Summary.Cart.Empty.Icon />
                    Your cart is empty.
                    <Summary.Cart.Empty.Description>
                        Looks like you don't have any products/services in your cart, why not head over to the shop and check out what is on offer!
                    </Summary.Cart.Empty.Description>
                    <Summary.Cart.Empty.Link color={`select`} onClick={() => navigate('/shop/purchase')}>
                        Go to Shop
                    </Summary.Cart.Empty.Link>
                </Summary.Cart.Empty.Base>
            )
        }

        if (cart.items.some(({ pending }) => pending)) {
            return (
                <Summary.Cart.Empty.Base>
                    <Summary.Cart.Empty.Configure />
                    <Summary.Cart.Empty.Description>
                        Looks like you have pending cart item in your cart, please configure all of your cart items before proceeding to checkout!
                    </Summary.Cart.Empty.Description>
                    <Summary.Cart.Empty.Link color={`select`} onClick={() => navigate('/shop/purchase')}>
                        Go to Shop
                    </Summary.Cart.Empty.Link>
                </Summary.Cart.Empty.Base>
            )
        }

        return (
            <CartItem.List>
                {cart.items.map((item) => (
                    <CartItemComponent key={item.item_uuid} cartItem={item} />
                ))}
            </CartItem.List>
        )
    }

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Checkout.Base>
            <PaymentMethods />
            <Checkout.Content>
                <Payment.Header>
                    <Payment.Title>Order Summary</Payment.Title>
                </Payment.Header>
                {renderCartItemList()}
            </Checkout.Content>
        </Checkout.Base>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSearchParams } from 'react-router-dom'

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Provides access to the state of the `promo` search param in the URL and methods to update it.
 */
export const usePromotionSearchParam = () => {
    /***** HOOKS *****/
    const [params, setParams] = useSearchParams()

    /***** RENDER *****/
    return [
        params.get('promo'),
        {
            removePromotion: () =>
                setParams((params) => {
                    params.delete('promo')
                    return params
                }),
            setPromotion: (promo: string) =>
                setParams((params) => {
                    params.set('promo', promo)
                    return params
                })
        }
    ] as const
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
